// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { DevFittrPageVisits, DevFittr3DScans, DevFittrOrganisations, DevFittrLocations, DevFittrLast, DevFittrModel, DevFittrOrder, DevFittrClosing, DevFittrStyle, DevFittrColor, DevFittrSpec, DevFittrHeight, DevFittrPurchasePrice, DevFittrPriceLimit, DevModelCollection, FootcareWelt, FootcareApplicatie, FootcareOutsole, FootcareUpperleather, FootcareBadslipper, FootcareMaatpantoffel, FootcareComfortslipper, FootcareUitwasbareschoen, FootcareSandaal, FootcareClosingItem, DevFootcareModel, DevFootcarePriceLevel, DevFootcareSupplier, DevFootcareHealthcareProvider, DevFootcareStyle, DevFootcareClosing, DevFootcareInstep, DevFootcareHeight } = initSchema(schema);

export {
  DevFittrPageVisits,
  DevFittr3DScans,
  DevFittrOrganisations,
  DevFittrLocations,
  DevFittrLast,
  DevFittrModel,
  DevFittrOrder,
  DevFittrClosing,
  DevFittrStyle,
  DevFittrColor,
  DevFittrSpec,
  DevFittrHeight,
  DevFittrPurchasePrice,
  DevFittrPriceLimit,
  DevModelCollection,
  FootcareWelt,
  FootcareApplicatie,
  FootcareOutsole,
  FootcareUpperleather,
  FootcareBadslipper,
  FootcareMaatpantoffel,
  FootcareComfortslipper,
  FootcareUitwasbareschoen,
  FootcareSandaal,
  FootcareClosingItem,
  DevFootcareModel,
  DevFootcarePriceLevel,
  DevFootcareSupplier,
  DevFootcareHealthcareProvider,
  DevFootcareStyle,
  DevFootcareClosing,
  DevFootcareInstep,
  DevFootcareHeight
};