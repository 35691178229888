<script setup>
import {
  IonBackButton,
  IonMenuButton,
  IonBadge,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonButton,
  IonMenuToggle,
  IonSearchbar, menuController,
} from "@ionic/vue";
import {QTooltip, QBanner, QBtn} from "quasar";
import {computed, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import EventBus from "../Eventbus";
import {useGlobalStore} from "../store/global";

// Access the global store
const store = useGlobalStore();
const router = useRouter();
const route = useRoute();

const {
  showFavorites,
  setAllFavorites,
  searchModels,
  helpText,
  favoritesButton,
  allFavoritesButton,
  functionalFiltersButton,
  showSearch
} = defineProps({
  setAllFavorites: Function,
  showFavorites: Function,
  searchModels: Function,
  showSearch: Boolean,
  helpText: String,
  favoritesButton: Boolean,
  allFavoritesButton: Boolean,
  functionalFiltersButton: Boolean,
});

// Computed property to check if there's a back route available
const hasBackRoute = computed(() => !!router.options.history.state.back);

// Computed property to determine the margin style for the 'Set All Favorites' button
const allFavoritesButtonMargin = computed(() => {
  return hasBackRoute.value
      ? {margin: "10px -16px 10px 10px"}
      : {margin: "10px 6px 10px 10px"};
});

// Computed property to decide if the technical filters button should be shown
const showTechnicalFiltersButton = computed(() => {
  return route.path.includes("osb") && hasBackRoute.value;
});

// Function to open the help modal
function openTheModal() {
  // Set the help content in the store
  store.helpContent = helpText
  // Emit an event to open the help modal
  EventBus.emit("openHelpModal");
}

// Function to navigate home or to a specific URL
function navigateHome() {
  if (hasBackRoute.value) {
    // Navigate to the origin URL
    window.location.href = window.location.origin;
  } else {
    // Navigate to the URL specified in the store
    window.location.href = store.url;
  }
}

const themeToColor = {
  fittr: 'primary',
  footcare: 'primary',
  penders: 'primary',
  kievit: 'light',
  wittepoel: 'primary',
  voetmax: 'light'
};
const color = themeToColor[store.organisational_theme] || 'primary';

const dismissBanner = ref(false);

const openLogin = () => {
  menuController.open();
  store.accordionGroup.value = 'colors'
  console.log(store.accordionGroup)
};

</script>

<template>
  <ion-header :translucent="true">
    <ion-toolbar :class="store.organisational_theme" :color="color">
      <ion-buttons slot="start">
        <!-- Back Button: Shows if there's a back route -->
        <ion-back-button v-if="hasBackRoute" default-href="/home"/>

        <!-- Header Logo: Navigates home when clicked -->
        <img @click="navigateHome" class="header-logo" alt="no-image" :src="store.app_logo"/>
      </ion-buttons>

      <ion-buttons slot="end">
        <!-- Help Modal Button: Shows on larger screens if there's a back route -->
        <ion-button v-if="hasBackRoute" class="ion-hide-sm-down header-button" @click="openTheModal()">
          <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
            Open uitleg voor deze pagina
          </q-tooltip>
          <i slot="icon-only" class="fa-regular fa-question-circle header-icon"></i>
        </ion-button>

        <!-- Search Bar: Visible on larger screens -->
        <ion-searchbar v-if="showSearch" class="ion-hide-sm-down searchbar-custom" @["ion-input"]="searchModels($event)" inputmode="search" :debounce="750"></ion-searchbar>

        <!-- Show Favorites Button -->
        <ion-button v-if="favoritesButton" @click="showFavorites" class="favorites-button">
          <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
            Toon geselecteerde favorieten
          </q-tooltip>
          <!-- Badge showing the number of favorites -->
          <ion-badge v-if="store.favorites.length > 0" color="secondary" class="favorites-badge">
            {{ store.favorites.length }}
          </ion-badge>
          <i slot="icon-only" class="fa-regular fa-heart header-icon"></i>
        </ion-button>

        <!-- Set All Favorites Button -->
        <ion-button v-if="allFavoritesButton" @click="setAllFavorites" :style="allFavoritesButtonMargin" class="set-all-favorites-button">
          <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
            (De)Selecteer alle modellen als favoriet
          </q-tooltip>
          <!-- Overlay icons to represent adding all to favorites -->
          <div class="plus-icon-background"></div>
          <i v-if="store.favorites.length === 0" class="fa-solid fa-square-plus plus-icon"></i>
          <i v-else class="fa-solid fa-square-minus plus-icon"></i>
          <i slot="icon-only" class="fa-regular fa-heart header-icon"></i>
        </ion-button>

        <!-- Technical Filters Button: Shows if on 'osb' route and there's a back route -->
        <ion-button v-if="showTechnicalFiltersButton && functionalFiltersButton" class="technical-filters-button">
          <q-tooltip :delay="500" max-width="50%" style="font-size: 16px">
            Open het menu technische filters
          </q-tooltip>
          <div slot="icon-only">
            <!-- Menu Toggle for technical filters -->
            <ion-menu-toggle menu="men" class="menu-toggle-overlay"></ion-menu-toggle>
            <i class="fa-regular fa-cog header-icon"></i>
          </div>
        </ion-button>

        <!-- Menu Button: Shows if there's a back route -->
        <ion-menu-button v-if="hasBackRoute" class="menu-button">
          <!-- Badge indicating user is not logged in -->
          <ion-badge v-if="!store.logged_in_user && hasBackRoute" color="secondary" class="user-badge">
            <i class="fa-regular fa-user-xmark"></i>
          </ion-badge>
          <i class="fa-regular fa-bars header-icon"></i>
        </ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <q-banner v-if="!store.logged_in_user && dismissBanner !== true && hasBackRoute" inline-actions class="bg-red text-white" style="
    z-index: 10000">
    Je bent niet ingelogd, niet alle functies zijn beschikbaar!
    <template v-slot:action>
      <q-btn @click="openLogin" flat label="Login"/>
    </template>
  </q-banner>
</template>

<style>
.q-banner {
  min-height: 30px!important;
  padding: 0 16px!important;
}
.q-banner__content {
  font-size: 16px!important;
  text-align: center;
}
.searchbar-custom input {
  margin-left: 4px;
}

.searchbar-custom ion-icon {
  margin-left: 4px;
}
</style>

<style scoped>

/* Styles for the header logo */
.header-logo {
  height: 50px;
  margin: 10px 10px 10px 16px;
  /* Add any necessary styles for the logo */
  cursor: pointer;
}

/* General styles for header buttons */
.header-button {
  height: 50px;
  width: 50px;
  margin: 10px 6px 10px 10px;
}

/* Icon styling */
.header-icon {
  font-size: 24px;
}

/* Custom styles for the search bar */
.searchbar-custom {
  text-align: start;
  padding-bottom: 0;
  padding-top: 0;
  min-height: 0;
  width: 200px;
}

/* Styles for the favorites button */
.favorites-button {
  height: 50px !important;
  width: 50px !important;
  margin: 10px -16px 10px 10px !important;
  position: relative;
}

/* Positioning the favorites badge */
.favorites-badge {
  position: fixed;
  top: 7px;
  right: 6px;
}

/* Styles for the 'Set All Favorites' button */
.set-all-favorites-button {
  height: 50px;
  width: 50px;
  position: relative;
}

/* Background for the plus icon */
.plus-icon-background {
  position: fixed;
  top: 12px;
  right: 10px;
  width: 14px;
  height: 14px;
  background: white;
}

/* Styling for the plus icon */
.plus-icon {
  color: var(--ion-color-secondary);
  font-size: 22px;
  position: fixed;
  top: 6px;
  right: 4px;
  margin: 0;
  padding: 0;
}

/* Styles for the technical filters button */
.technical-filters-button {
  height: 50px !important;
  width: 50px !important;
  margin: 10px -16px 10px 10px !important;
  position: relative;
}

/* Overlay to capture click events for the menu toggle */
.menu-toggle-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* Styles for the menu button */
.menu-button {
  width: 50px;
  height: 50px;
  margin: 10px 6px 10px 10px;
  position: relative;
}

/* Badge indicating the user is not logged in */
.user-badge {
  position: fixed;
  right: 6px;
  top: 18px;
}
</style>