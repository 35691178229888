import {generateClient} from "aws-amplify/api";
import {defineStore} from "pinia";
import {Network} from "@capacitor/network";
import {
	DataStore,
	SortDirection,
	syncExpression,
} from "aws-amplify/datastore";
import {
	DevFittrOrder,
	DevFittrLast,
	DevFittrModel,
	DevFittrPriceLimit,
} from "@/models";
import {listDevFittrPriceLimits} from "../graphql/queries";
import {Capacitor} from "@capacitor/core";
import {fetchUserAttributes} from "aws-amplify/auth";

import {listDevFootcareModels} from "../graphql/queries";

export const useGlobalStore = defineStore("global", {
	state: () => {
		return {
			Lasts: DevFittrLast,
			Models: DevFittrModel,
			scanLengthLeft: 0,
			scanWidthLeft: 0,
			scanCircumfenceLeft: 0,
			scanLengthRight: 0,
			scanWidthRight: 0,
			scanCircumfenceRight: 0,
			/* color_scheme: 'dark', */
			color_scheme: "light",
			logged_in_user: undefined,
			organisation: "fittr",
			priceFilter: true,
			category: "",
			lastImage: false,
			lastShapeImage: false,
			stiffnerImage: false,
			contrefortTypeImage: false,
			outsoleImage: false,
			signedIn: false,
			priceLimit: 0,
			brands: [],
			imageLoadingState: true,
			filterLoadingState: true,
			modelCollection: {},
			modelsPerPage: 20,
			models: [],
			lastSizes: [],
			initialisedModels: [],
			filteredModels:[],
			expensiveModels: [],
			submittedOrders: [],
			archivedOrders: [],
			cheapModels: [],
			activeModel: {},
			activeModels: [],
			lastLeft: {},
			lastRight: {},
			activeOrder: {},
			footData: {},
			lastProposals: {},
			filters: {
				styles: [],
				height: [],
				closings: [],
				colors: [],
				priceLevel: [],
				healthcareProviders: [],
				suppliers: [],
				brand: [],
				specs: [],
			},
			isOpenRef: false,
			selectedFilters: [],
			favorites: [],
			themes: [
				'fittr',
				'footcare',
				'penders',
				'kievit',
				'wittepoel',
				'voetmax',
			],
			shareUrl: "https://fittrapp.com/osb/models/",
			redirectUrl: "https://fittrapp.com",
			url: "https://fittrapp.com",
			app_logo: undefined,
			imagePath: "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/",
			isOpen: false,
			favoritesOpen: false,
			scrollState: false,
			helpContent: "",
			organisational_theme: 'fittr',
			test: false,
			accordionGroup: null,
			size_conversion: [
				{
					"maat_id": 0,
					"size": 2.5
				},
				{
					"maat_id": 1,
					"size": 3
				},
				{
					"maat_id": 2,
					"size": 3.5
				},
				{
					"maat_id": 3,
					"size": 4
				},
				{
					"maat_id": 4,
					"size": 4.5
				},
				{
					"maat_id": 5,
					"size": 5
				},
				{
					"maat_id": 6,
					"size": 5.5
				},
				{
					"maat_id": 7,
					"size": 6
				},
				{
					"maat_id": 8,
					"size": 6.5
				},
				{
					"maat_id": 9,
					"size": 7
				},
				{
					"maat_id": 10,
					"size": 7.5
				},
				{
					"maat_id": 11,
					"size": 8
				},
				{
					"maat_id": 12,
					"size": 8.5
				},
				{
					"maat_id": 13,
					"size": 9
				},
				{
					"maat_id": 14,
					"size": 9.5
				},
				{
					"maat_id": 15,
					"size": 10
				},
				{
					"maat_id": 16,
					"size": 10.5
				},
				{
					"maat_id": 17,
					"size": 11
				},
				{
					"maat_id": 18,
					"size": 11.5
				},
				{
					"maat_id": 19,
					"size": 12
				},
				{
					"maat_id": 20,
					"size": 12.5
				},
				{
					"maat_id": 21,
					"size": 13
				},
				{
					"maat_id": 22,
					"size": 13.5
				}
			]
		};
	},
	actions: {
		async getLoggedInUser() {
			try {
				const user = await fetchUserAttributes();
				return user;
			} catch (err) {
				console.log(err);
			}
		},
		$resetOrder() {
			this.activeOrder = {};
		},
		resetStoreValues() {
			this.models = [];
			this.initialisedModels = [];
			this.cheapModels = [];
			this.expensiveModels = [];
			this.filters = {
				styles: [],
				height: [],
				closings: [],
				colors: [],
				priceLevel: [],
				healthcareProviders: [],
				suppliers: [],
				brand: [],
				specs: [],
			};
			this.imageLoadingState = true;
			this.filterLoadingState = true;
			this.modelCollection = {};
			this.activeOrder = {};
			this.scanLengthLeft = 0;
			this.scanWidthLeft = 0;
			this.scanCircumfenceLeft = 0;
			this.priceFilter = true;
			this.favorites = [];
			this.activeModel = {};
			this.activeModels = [];
		},
		showAlert(message) {
			$q.notify({
				color: "red-5",
				position: "center",
				textColor: "white",
				icon: "fas fa-triangle-exclamation",
				message: message,
			});
		},
		setOpen(open) {
			this.isOpenRef = open;
			if (open === false) {
				this.favoritesOpen = false;
			}
		},
		findUniqueModelValue(column) {
			const length = this.initialisedModels.length;
			const result = new Set();
			for (let i = 0; i < length; i++) {
				let model = this.initialisedModels[i];
				if (column === "brand") {
					result.add(model[column]);
				} else {
					for (let j = 0; j < model.attributes?.[column]?.length; j++) {
						result.add(model.attributes?.[column][j]);
					}
				}
			}
			return [...result];
		},
		async toggleFilter(propName, it, route) {
			if (propName !== undefined) {
				if (!this.filters[propName]) {
					// Ensure the property exists and is reactive
					Vue.set(this.filters, propName, []);
				}
				const index = this.filters[propName].indexOf(it);
				if (index === -1) {
					this.filters[propName].push(it);
				} else {
					this.filters[propName].splice(index, 1);
				}
			}
			
			let source;
			if (route.path.includes("osb")) {
				source = this.priceFilter ? this.cheapModels : this.expensiveModels;
			} else {
				source = this.initialisedModels;
			}
			
			const activeFilters = Object.entries(this.filters).filter(
				([, values]) => values.length > 0
			);
			
			if (activeFilters.length === 0) {
				return source;
			}
			
			const dest = source.filter((model) => {
				return activeFilters.every(([key, filterValues]) => {
					const modelValues =
						key !== "brand" ? model.attributes[key] : model[key];
					
					if (!modelValues) return false;
					
					const modelValueSet = new Set(
						Array.isArray(modelValues) ? modelValues : [modelValues]
					);
					
					return filterValues.some((value) => modelValueSet.has(value));
				});
			});
			
			console.log("Visible models after filter toggle: " + dest.length);
			return dest;
		},
		async getPriceLimit(organisation, hasHistory) {
			const client = generateClient();
			if (hasHistory) {
				const filter = {
					organisation: {
						contains: organisation, // filter priority = 1
					},
				};
				const response = await client.graphql({
					query: listDevFittrPriceLimits,
					variables: {filter},
				});
				const items = response.data.listDevFittrPriceLimits.items;
				this.priceLimit = items[0].amount;
				return items[0].amount;
			} else {
				try {
					const limit = await DataStore.query(DevFittrPriceLimit, (c) =>
						c.organisation.contains(organisation)
					);
					this.priceLimit = limit[0].amount;
					return limit[0].amount;
				} catch (e) {
					return "User not logged in!";
				}
			}
		},
		async updatePriceLimit(user, price) {
			const limit = await DataStore.query(DevFittrPriceLimit, (c) =>
				c.organisation.contains(user["custom:organisation"].toString())
			);
			const original = limit[0];
			if (original) {
				const updatedPost = await DataStore.save(
					DevFittrPriceLimit.copyOf(original, (updated) => {
						updated.amount = price;
					})
				);
				this.priceLimit = price;
			}
		},
		getModelPrice(model) {
			let price = 0;
			if (model.prices?.price) {
				if (model.prices?.price === "" || model.prices?.price === "€0") {
					price = 165;
				} else {
					price = parseFloat(
						model.prices.price.replace("€", "").replaceAll(" ", "")
					);
				}
			} else if (model.prices?.maten) {
				for (const property in model.prices.maten) {
					if (property.toString().includes("-") === true) {
						let sizerange = property.toString().split("-");
						try {
							if (
								model.size_left.toString() >= sizerange[0].toString() &&
								model.size_left.toString() <= sizerange[1].toString()
							) {
								price = parseFloat(
									model.prices.maten[property.toString()].replace("€", "")
								);
							}
						} catch (e) {
							if (parseFloat(model.prices.maten[property.toString()]) !== "") {
								try {
									price = parseFloat(model.prices.maten[0].replace("€", ""));
								} catch (e) {
									try {
										price = parseFloat(model.prices.maten[1].replace("€", ""));
									} catch (e) {
										try {
											price = parseFloat(
												model.prices.maten[2].replace("€", "")
											);
										} catch (e) {
											try {
												price = parseFloat(
													model.prices.maten[3].replace("€", "")
												);
											} catch (e) {
												try {
													price = parseFloat(
														model.prices.maten[4].replace("€", "")
													);
												} catch (e) {
													try {
														price = parseFloat(
															model.prices.maten[5].replace("€", "")
														);
													} catch (e) {}
												}
											}
										}
									}
								}
							}
						}
					} else {
						try {
							if (model.size_left.toString() === property.toString()) {
								price = parseFloat(
									model.prices.maten[property.toString()].replace("€", "")
								);
							}
						} catch (e) {
							if (parseFloat(model.prices.maten[property.toString()]) !== "") {
								price = parseFloat(
									model.prices.maten[property.toString()].replace("€", "")
								);
							}
						}
					}
				}
			} else if (model.prices?.lasttypes) {
				for (const property in model.prices.lasttypes) {
					try {
						if (model.lasttype_left.toString() === property.toString()) {
							price = parseFloat(
								model.prices.lasttypes[property.toString()].replace("€", "")
							);
						}
					} catch (e) {
						if (
							parseFloat(model.prices.lasttypes[property.toString()]) !== ""
						) {
							price = parseFloat(
								model.prices.lasttypes[property.toString()].replace("€", "")
							);
						}
					}
				}
			}
			return price;
		},
		async getSubmittedOrders() {
			const temp = await DataStore.query(
				DevFittrOrder,
				(c) =>
					c.and((c) => [
						c.organisation.eq(this.logged_in_user["custom:organisation"]),
						c.userName.eq(this.logged_in_user.email),
					]),
				{
					sort: (s) => s.updatedAt(SortDirection.DESCENDING),
					page: 0,
					limit: 20,
				}
			);
			
			this.submittedOrders = temp.filter(
				(order) =>
					order.status.includes("submitted") ||
					order.status.includes("processed")
			);
			
			this.submittedOrders.push("last_item");
			return this.submittedOrders;
		},
		async getArchivedOrders() {
			this.archivedOrders = await DataStore.query(
				DevFittrOrder,
				(c) =>
					c.and((c) => [
						c.status.contains("archived"),
						c.userName.eq(this.logged_in_user.email),
					]),
				{
					sort: (s) => s.updatedAt(SortDirection.DESCENDING),
				}
			);
			return this.archivedOrders;
		},
		async searchSubmittedOrders(value) {
			if (value === "") {
				await this.getSubmittedOrders();
			} else {
				const lowerCased = value.replaceAll(" ", "").toLowerCase();
				this.submittedOrders = await DataStore.query(
					DevFittrOrder,
					(c) =>
						c.and((c) => [
							c.search.contains(lowerCased),
							c.userName.eq(this.logged_in_user.email),
						]),
					{
						sort: (s) => s.updatedAt(SortDirection.DESCENDING),
					}
				);
			}
		},
		async searchArchivedOrders(value) {
			const user = await fetchUserAttributes();
			if (value === "") {
				await this.getArchivedOrders();
			} else {
				const lowerCased = value.replaceAll(" ", "").toLowerCase();
				this.archivedOrders = await DataStore.query(
					DevFittrOrder,
					(c) =>
						c.and((c) => [
							c.search.contains(lowerCased),
							c.status.contains("archived"),
							c.userName.eq(this.logged_in_user.email),
						]),
					{
						sort: (s) => s.updatedAt(SortDirection.DESCENDING),
					}
				);
			}
		},
		async dbSync() {
			console.log("setting Cloudsync data");
			fetchUserAttributes().then((user) => {
				DataStore.configure({
					syncExpressions: [
						syncExpression(DevFittrOrder, () => {
							return (order) =>
								order.organisation.eq(user["custom:organisation"]);
						}),
					],
				});
			});
		},
		async showAlert(message) {
			const showAlert = (message) => {
				$q.notify({
					color: "red-5",
					position: "center",
					textColor: "white",
					icon: "fas fa-triangle-exclamation",
					message: message,
				});
			};
		},
		async initAppGlobals() {
			Network.addListener("networkStatusChange", async (status) => {
				console.log("Network status changed", status);
				const result = await Network.getStatus();
				this.imagePath = result.connected
					? "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/"
					: Capacitor.convertFileSrc(window.location.origin + "/model_images/");
			});
			// const result = await Network.getStatus();
			// this.imagePath = result.connected
			//   ? "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/"
			//   : Capacitor.convertFileSrc(window.location.origin + "/model_images/");
		},
	},
});
