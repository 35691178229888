<template>
  <IonPage>
    <FtrHeader :helpText="helptext_3dscan_page"/>

    <IonContent :color="store.url.includes('kievit') ? 'white' : 'light'" style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start; padding: 10px;">
      <ion-grid>
        <ion-row>
          <!-- Left Foot Viewer -->
          <ion-col size-xs="12" size-sm="6" size-md="5" size-lg="5" size-xl="5">
            <ion-card style="padding: 0; margin-top: 8px; margin-left: 8px; margin-right: 0;" class="custom-card">
              <ion-card-content style="padding: 12px">
                <ion-grid>
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="11" style="padding: 0; margin: 0">
                      <div style="border: 1px solid var(--ion-color-dark); border-radius: 6px">
                        <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                          <div style="min-height: 70vh; border-radius: 5px" ref="target_left"></div>
                        </transition>
                        <q-inner-loading :showing="visible">
                          <q-spinner-hourglass size="50px" color="secondary"/>
                          <p style="font-size:1.5em"><b>{{ loading_text }}</b></p>
                        </q-inner-loading>
                      </div>
                    </ion-col>

                    <!-- Manual rotate & move controls -->
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="1" style="padding: 0; margin: 0">
                      <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly">
                        <ion-button @click="rotateModelOnZPlane('left', 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-left"></i>
                        </ion-button>
                        <ion-button @click="rotateModelOnZPlane('left', -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-right"></i>
                        </ion-button>

                        <ion-button @click="moveModelOnZPlane('left', -1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-left"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-right"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 0, -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-up"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 0, 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-down"></i>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <!-- Middle Column: measurements & actions -->
          <ion-col size-xs="12" size-sm="6" size-md="2" size-lg="2" size-xl="2">
            <ion-card v-if="width_left && width_right" class="custom-card" style="padding: 0; margin-top: 8px; margin-left: 8px; margin-right: 8px; margin-bottom: 16px;">
              <ion-card-content style="padding-inline-start: 10px; padding-inline-end: 10px; display: flex; flex-direction: column;">
                <q-markup-table separator="vertical" dense flat :wrap-cells="true" style="width: 100%">
                  <thead>
                  <tr>
                    <th style="text-align: center; font-size: 10px; font-weight: bold"></th>
                    <th style="padding: 0; text-align: center; font-size: 10px; font-weight: bold">Links</th>
                    <th style="padding: 0; text-align: center; font-size: 10px; font-weight: bold">Rechts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">Lengte:</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(length_left) }}</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(length_right) }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">Omvang:</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(circumference_left) }}</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(circumference_right) }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">Breedte:</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(width_left) }}</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(width_right) }}</td>
                  </tr>
                  </tbody>
                </q-markup-table>
              </ion-card-content>
            </ion-card>

            <ion-card class="custom-card" style="padding: 0; margin: 8px">
              <ion-card-content style="display: flex; flex-direction: column">
                <ion-button color="secondary" v-if="!files_loaded_left || !files_loaded_right" @click="setOpen(true, 'upload')">
                  <i style="padding-right: 6px" class="fa-regular fa-cloud-arrow-up"></i>
                  Upload STL/PLY
                </ion-button>
                <ion-button color="secondary" v-if="!files_loaded_left || !files_loaded_right" @click="setOpen(true, 'library')" style="margin-top: 16px">
                  <i style="padding-right: 6px" class="fa-regular fa-rectangle-list"></i>
                  Bibliotheek
                </ion-button>
                <ion-button v-if="files_loaded_left && files_loaded_right" color="danger" style="margin-top: 16px" @click="removeScans">
                  <i style="padding-right: 6px" class="fa-regular fa-eraser"></i>
                  Remove Scans
                </ion-button>
                <ion-button color="secondary" v-if="files_loaded_left && files_loaded_right" @click="navigate" style="margin-top: 16px">
                  <i style="padding-right: 6px" class="fa-regular fa-chevron-right"></i>
                  Verder
                </ion-button>
              </ion-card-content>
            </ion-card>
            <ion-card style="padding: 16px; background-color: #ffffff;">
              <ion-card-content style="display: flex; flex-direction: column; align-items: center; text-align: center; gap: 12px;">

                <!-- App Icon -->
                <img src="../assets/img/scan_icon.png"
                     alt="FittrScan App Icon"
                     style="width: 120px; height: 120px; border-radius: 18.5%; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);" />

                <!-- Heading -->
                <div style="font-size: 14px; font-weight: bold; color: #333; margin: 8px 16px 4px;">
                  Heeft u een iPhone met gezichtsherkenning?
                </div>

                <!-- Description -->
                <p style="font-size: 12px; color: #666; line-height: 1.2; margin: 0;">
                  Download nu <strong>FittrScan</strong>, onze 3D-voetscanner—<br>
                  geen handmatige uploads meer nodig!
                </p>

                <!-- Download Button -->
                <ion-button expand="block" shape="round" style="--background: #FF7B00; --color: #fff; margin-top: 12px; padding: 12px 24px; font-size: 16px;">
                  Download de App
                </ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <!-- Right Foot Viewer -->
          <ion-col size-xs="12" size-sm="6" size-md="5" size-lg="5" size-xl="5">
            <ion-card style="padding: 0; margin-top: 8px; margin-left: 0; margin-right: 8px;" class="custom-card">
              <ion-card-content style="padding: 12px">
                <ion-grid>
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="11" style="padding: 0; margin: 0">
                      <div style="border: 1px solid var(--ion-color-dark); border-radius: 6px">
                        <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                          <div style="min-height: 70vh; border-radius: 5px" ref="target_right"></div>
                        </transition>
                        <q-inner-loading :showing="visible">
                          <q-spinner-hourglass size="50px" color="secondary"/>
                          <p style="font-size:1.5em"><b>{{ loading_text }}</b></p>
                        </q-inner-loading>
                      </div>
                    </ion-col>

                    <!-- Manual rotate & move controls -->
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="1" style="padding: 0; margin: 0">
                      <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly">
                        <ion-button @click="rotateModelOnZPlane('right', 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-left"></i>
                        </ion-button>
                        <ion-button @click="rotateModelOnZPlane('right', -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-right"></i>
                        </ion-button>

                        <ion-button @click="moveModelOnZPlane('right', -1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-left"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-right"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 0, -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-up"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 0, 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-down"></i>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- Modal for Upload & Library -->
      <ion-modal :is-open="isOpen">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="setOpen(false, null)">Cancel</ion-button>
            </ion-buttons>
            <ion-buttons slot="end" v-if="contentType === 'upload'">
              <ion-button @click="saveScans" :strong="true">Confirm</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content v-if="contentType === 'upload'" class="ion-padding">
          <div style="display: flex; flex-direction: row; justify-content: space-evenly">
            <q-input label="Klantnummer/geboortedatum" v-model="clientIdOne" ref="inputRef" type="text" name="clientIdOne" style="width: 40%"/>
            <q-input label="Eerste letters achternaam" v-model="clientIdTwo" ref="inputRef" type="text" name="clientIdTwo" style="width: 40%"/>
          </div>
          <ion-toolbar style="margin-top: 16px; text-align: center">
            <ion-title>STL Files</ion-title>
          </ion-toolbar>
          <div style="display: flex; flex-direction: row; justify-content: space-evenly">
            <q-uploader @added="onAdded($event, 'left')" hide-upload-btn label="Links" style="width: 40%"/>
            <q-uploader @added="onAdded($event, 'right')" hide-upload-btn label="Rechts" style="width: 40%"/>
          </div>
        </ion-content>

        <ion-content v-if="contentType === 'library'" class="ion-padding">
          <q-list class="rounded-borders">
            <q-separator spaced/>
            <template v-for="item in library" :key="item.id">
              <q-item>
                <q-item-section avatar top>
                  <q-avatar color="grey" text-color="white">
                    <i style="font-size: 22px" class="fal fa-chart-scatter-3d"></i>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label style="font-weight: bold" lines="1">
                    {{ item.client_id_two + " " + item.client_id_one }}
                  </q-item-label>
                  <q-item-label caption>{{ normalizeDate(item.date) }}</q-item-label>
                  <q-item-label>Files:</q-item-label>
                  <q-item-label style="margin-left: 16px" caption>{{ item.filename_left }}</q-item-label>
                  <q-item-label style="margin-left: 16px" caption>{{ item.filename_right }}</q-item-label>
                  <q-item-label v-if="item.filename_inlay_left || item.filename_inlay_right">Inlay files:</q-item-label>
                  <q-item-label v-if="item.filename_inlay_left"style="margin-left: 16px" caption>{{ item.filename_inlay_left }}<ion-button @click="downloadScanFromBrowser(item.filename_inlay_left)" fill="clear" shape="round" style="margin: -16px 32px 0 32px;"><i style="font-weight: 500;
    font-size: 20px;" slot="icon-only"  class="fal fa-download"></i></ion-button></q-item-label>
                  <q-item-label v-if="item.filename_inlay_right"style="margin-left: 16px" caption>{{ item.filename_inlay_right }}<ion-button @click="downloadScanFromBrowser(item.filename_inlay_right)" fill="clear" shape="round" style="margin: -16px 32px 0 32px;"><i style="font-weight: 500;
    font-size: 20px;" slot="icon-only"  class="fal fa-download"></i></ion-button></q-item-label>
                </q-item-section>

                <q-item-section side>
                  <ion-button @click="loadScan(item.id)" fill="clear" shape="round"><i style="font-size: 22px" class="fal fa-arrow-right"></i></ion-button>
                </q-item-section>
              </q-item>
              <q-separator spaced/>
            </template>
          </q-list>
        </ion-content>
      </ion-modal>
    </IonContent>
  </IonPage>
</template>

<script setup>
/* ------------------- ALL IMPORTS ------------------- */
import {
  IonButton, IonButtons, IonCard, IonCardContent, IonCol,
  IonContent, IonGrid, IonHeader, IonModal, IonPage,
  IonRow, IonToolbar, IonTitle
} from "@ionic/vue";
import {QMarkupTable, QInnerLoading, QSpinnerHourglass} from "quasar";
import {DataStore, SortDirection} from "aws-amplify/datastore";
import {downloadData, uploadData, getUrl} from "aws-amplify/storage";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {PLYLoader} from "three/examples/jsm/loaders/PLYLoader";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {onBeforeUnmount, onMounted, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import outline_left from "../assets/outline_L.png";
import outline_right from "../assets/outline_R.png";
import FtrHeader from "../components/FtrHeader.vue";
import {helptext_3dscan_page} from "../locales/HelptTextContent";
import {DevFittr3DScans} from "../models";
import {useGlobalStore} from "../store/global";


const store = useGlobalStore();
const router = useRouter();
const route = useRoute();
const visible = ref(false);
const loading_text = ref('');
loading_text.value = 'Loading 3D file...';
let rotationTimeout;
const isOpen = ref(false);
const contentType = ref("");
const library = ref();

const setOpen = async (open, content) => {
  if (content === "upload") {
    contentType.value = "upload";
  } else if (content === "library") {
    let category;
    if (route.params.category.includes('f')) {
      category = 'f';
    } else if (route.params.category.includes('m')) {
      category = 'm';
    }
    library.value = await DataStore.query(
        DevFittr3DScans,
        (c) =>
            c.and((c) => [
              c.gender.contains(category),
              c.user.contains(store.logged_in_user.email),
            ]),
        {
          sort: (s) => s.date(SortDirection.DESCENDING),
        }
    );
    console.log(library.value);
    contentType.value = "library";
  }
  isOpen.value = open;
};

const clientIdOne = ref("");
const clientIdTwo = ref("");
const filename_left = ref("");
const filename_right = ref("");
const file_left = ref();
const file_right = ref();
const width_left = ref();
const circumference_left = ref();
const length_left = ref();
const width_right = ref();
const circumference_right = ref();
const length_right = ref();
const files_loaded_left = ref(false);
const files_loaded_right = ref(false);

async function saveScans() {
  const category = JSON.parse(route.params.category);
  const user = await store.getLoggedInUser();
  console.log(user);
  visible.value = true;
  const date = Date.now();
  const saved_model = await DataStore.save(
      new DevFittr3DScans({
        client_id_one: clientIdOne.value,
        client_id_two: clientIdTwo.value,
        user: user.email,
        gender: category.category,
        filename_left: filename_left.value,
        filename_right: filename_right.value,
        organisation: user["custom:organisation_id"],
        date: date,
      })
  );
  console.log(saved_model);
  await setOpen(false);
  await loadScan(saved_model.id)
}
// --- Required Imports (for module-based projects) ---
import { mergeVertices, mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';

// --------------------------------------------------------------------
// 1) Conversion Functions: Convert an OBJ (text) to a binary STL ArrayBuffer
// --------------------------------------------------------------------

function convertOBJToBinarySTL(objText) {
  // Parse the OBJ text into a Three.js Group.
  const objLoader = new OBJLoader();
  const group = objLoader.parse(objText);

  // Traverse the group to gather all mesh geometries.
  let geometries = [];
  group.traverse((child) => {
    if (child.isMesh && child.geometry) {
      // Ensure normals exist.
      child.geometry.computeVertexNormals();
      geometries.push(child.geometry);
    }
  });

  if (geometries.length === 0) {
    console.warn("No geometry found in OBJ.");
    return null;
  }

  // Merge geometries into a single BufferGeometry.
  let mergedGeometry;
  if (geometries.length === 1) {
    mergedGeometry = geometries[0];
  } else {
    mergedGeometry = mergeBufferGeometries(geometries, false);
  }

  // Convert the merged geometry into binary STL.
  return bufferGeometryToBinarySTL(mergedGeometry);
}

function bufferGeometryToBinarySTL(geometry) {
  // Ensure the geometry is indexed.
  if (!geometry.index) {
    geometry = mergeVertices(geometry);
  }

  const index = geometry.index.array;
  const positionAttr = geometry.attributes.position;
  const normalAttr = geometry.attributes.normal;
  const faceCount = index.length / 3;

  // Calculate the size of the binary STL:
  // 80-byte header + 4-byte face count + (faceCount * 50 bytes per face)
  const size = 84 + faceCount * 50;
  const buffer = new ArrayBuffer(size);
  const dataView = new DataView(buffer);
  let offset = 0;

  // 80-byte header (unused, so fill with zeros)
  for (let i = 0; i < 80; i++) {
    dataView.setUint8(offset, 0);
    offset++;
  }

  // Write face count (4-byte unsigned int)
  dataView.setUint32(offset, faceCount, true);
  offset += 4;

  // For each face, write normal, vertices, and a 2-byte attribute count.
  for (let f = 0; f < faceCount; f++) {
    const i1 = index[f * 3 + 0];
    const i2 = index[f * 3 + 1];
    const i3 = index[f * 3 + 2];

    // Use the normal from the first vertex.
    const nx = normalAttr.getX(i1);
    const ny = normalAttr.getY(i1);
    const nz = normalAttr.getZ(i1);
    dataView.setFloat32(offset, nx, true); offset += 4;
    dataView.setFloat32(offset, ny, true); offset += 4;
    dataView.setFloat32(offset, nz, true); offset += 4;

    // Write vertex coordinates for each of the 3 vertices.
    for (const idx of [i1, i2, i3]) {
      const x = positionAttr.getX(idx);
      const y = positionAttr.getY(idx);
      const z = positionAttr.getZ(idx);
      dataView.setFloat32(offset, x, true); offset += 4;
      dataView.setFloat32(offset, y, true); offset += 4;
      dataView.setFloat32(offset, z, true); offset += 4;
    }

    // Write 2-byte attribute byte count (typically zero).
    dataView.setUint16(offset, 0, true);
    offset += 2;
  }

  return buffer;
}

// --------------------------------------------------------------------
// 2) Helper Function: Convert a downloaded OBJ file Blob/ArrayBuffer to an STL file Blob.
// --------------------------------------------------------------------

async function convertOBJFileToSTL(file, filename) {
  let objText;
  // If the file has a .text() method (Blob), use it.
  if (typeof file.text === "function") {
    objText = await file.text();
  } else if (file instanceof ArrayBuffer) {
    // If it's an ArrayBuffer, decode it to a UTF-8 string.
    objText = new TextDecoder("utf-8").decode(new Uint8Array(file));
  } else {
    // Fallback conversion.
    objText = file.toString();
  }

  const stlArrayBuffer = convertOBJToBinarySTL(objText);
  if (!stlArrayBuffer) {
    throw new Error("OBJ-to-STL conversion failed.");
  }
  // Create a new Blob from the ArrayBuffer.
  const newBlob = new Blob([stlArrayBuffer], { type: "application/octet-stream" });
  // Replace the .obj extension with .stl.
  const newFilename = filename.replace(/\.obj$/i, ".stl");
  return { file: newBlob, filename: newFilename };
}

// --------------------------------------------------------------------
// 3) Updated loadScan Function: Convert OBJ scans to binary STL if needed.
// --------------------------------------------------------------------

async function loadScan(id) {
  // Close modal and show loading indicator.
  await setOpen(false);
  visible.value = true;

  // Query the scan.
  const scan = await DataStore.query(DevFittr3DScans, id);

  // Start both downloads concurrently.
  const [fileL, fileR] = await Promise.all([
    downloadScan(scan.filename_left),
    downloadScan(scan.filename_right)
  ]);

  // For each file, check if it's an OBJ.
  const leftFileData = scan.filename_left.toLowerCase().endsWith(".obj")
      ? await convertOBJFileToSTL(fileL.body, scan.filename_left)
      : { file: fileL.body, filename: scan.filename_left };
  console.log(leftFileData.filename);
  console.log(leftFileData.file);
  const rightFileData = scan.filename_right.toLowerCase().endsWith(".obj")
      ? await convertOBJFileToSTL(fileR.body, scan.filename_right)
      : { file: fileR.body, filename: scan.filename_right };

  // Load the models concurrently using the (now assured) STL file.
  loadSTL("left", leftFileData.file, leftFileData.filename);
  loadSTL("right", rightFileData.file, rightFileData.filename);
}

// --------------------------------------------------------------------
// 4) Your Existing downloadScan Function (unchanged)
// --------------------------------------------------------------------

async function downloadScan(filename) {
  return await downloadData({
    path: "public/3DScans/" + filename,
    options: {
      onProgress: (event) => {
        console.log(event.transferredBytes);
      },
    },
  }).result;
}

async function downloadScanFromBrowser(filename) {
  const getUrlResult = await getUrl({
    path: "public/3DScans/" + filename,
    // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
    options: {
      validateObjectExistence: false,  // Check if object exists before creating a URL
    },
  });
  console.log('signed URL: ', getUrlResult.url);
  console.log('URL expires at: ', getUrlResult.expiresAt);
  //window.open(getUrlResult.url)
  window.location.href = getUrlResult.url;
}

async function onAdded(files, side) {
  if (side === "left") {
    filename_left.value = files[0].name;
    file_left.value = files;
  } else {
    filename_right.value = files[0].name;
    file_right.value = files;
  }

  const objectUrl = URL.createObjectURL(files[0]);
  const reader = new FileReader();
  reader.onload = () => {
    URL.revokeObjectURL(objectUrl);
    try {
      uploadData({
        path: "public/3DScans/" + files[0].name,
        data: files[0],
        options: {
          onProgress: ({transferredBytes, totalBytes}) => {
            if (totalBytes) {
              console.log(
                  `Upload progress ${Math.round(
                      (transferredBytes / totalBytes) * 100
                  )} %`
              );
            }
          },
        },
      });
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  reader.readAsArrayBuffer(files[0]);
}



/* ------------------- HELPER: Format Date ------------------- */
function normalizeDate(date) {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const mon = String(d.getMonth() + 1).padStart(2, "0");
  const yr = d.getFullYear();
  const hrs = String(d.getHours()).padStart(2, "0");
  const mins = String(d.getMinutes()).padStart(2, "0");
  return `${day}-${mon}-${yr} ${hrs}:${mins}`;
}

/* ------------------------------------------------------
 3D Scenes, Cameras, Renderers, & OnMounted
 ------------------------------------------------------ */
const target_left = ref(null);
const target_right = ref(null);

let scene_left, camera_left, renderer_left, controls_left, footModel_left = null;
let scene_right, camera_right, renderer_right, controls_right, footModel_right = null;

function createSceneAndRenderer(container) {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);

  const camera = new THREE.PerspectiveCamera(
      40,
      container.clientWidth / container.clientHeight,
      0.1,
      5000
  );
  camera.position.set(0, 450, 0);
  camera.lookAt(0, 0, 0);
  camera.up.set(0, 1, 0);

  const renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.shadowMap.enabled = true;
  renderer.localClippingEnabled = true;

  // Add lights
  const ambientLight = new THREE.AmbientLight(0xffffff, 1.2);
  scene.add(ambientLight);

  const dirLight1 = new THREE.DirectionalLight(0xffffff, 2);
  dirLight1.position.set(50, 50, 100);
  dirLight1.castShadow = true;
  scene.add(dirLight1);

  const dirLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
  dirLight2.position.set(-50, 50, -100);
  dirLight2.castShadow = true;
  scene.add(dirLight2);

  const dirLight3 = new THREE.DirectionalLight(0xffffff, 1);
  dirLight3.position.set(0, 100, 0);
  dirLight3.castShadow = true;
  scene.add(dirLight3);

  const gridHelper = new THREE.GridHelper(400, 40);
  scene.add(gridHelper);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;

  const resizeRendererToFixedAspectRatio = () => {
    const w = container.clientWidth;
    const h = container.clientHeight;
    if (w > 0 && h > 0) {
      renderer.setSize(w, h);
      camera.aspect = w / h;
      camera.updateProjectionMatrix();
    }
  };
  const resizeObserver = new ResizeObserver(resizeRendererToFixedAspectRatio);
  resizeObserver.observe(container);

  onBeforeUnmount(() => resizeObserver.disconnect());
  resizeRendererToFixedAspectRatio();

  // Resize observer (omitted for brevity)
  return {scene, camera, renderer, controls};
}

function animateBoth() {
  requestAnimationFrame(animateBoth);
  if (controls_left && scene_left && camera_left && renderer_left) {
    controls_left.update();
    renderer_left.render(scene_left, camera_left);
  }
  if (controls_right && scene_right && camera_right && renderer_right) {
    controls_right.update();
    renderer_right.render(scene_right, camera_right);
  }
}

onMounted(() => {
  if (target_left.value) {
    const setupLeft = createSceneAndRenderer(target_left.value);
    scene_left = setupLeft.scene;
    camera_left = setupLeft.camera;
    renderer_left = setupLeft.renderer;
    controls_left = setupLeft.controls;
    target_left.value.appendChild(renderer_left.domElement);
    console.log("Left scene created with lights:", scene_left.children.filter(c => c.isLight));

    // Add event listeners for left side (mousedown, touch, etc.)
    target_left.value.addEventListener("mousedown", onMouseDownLeft);
    target_left.value.addEventListener("mousemove", onMouseMoveLeft);
    target_left.value.addEventListener("mouseup", onMouseUpLeft);
    target_left.value.addEventListener("touchstart", onTouchStartLeft);
    target_left.value.addEventListener("touchmove", onTouchMoveLeft);
    target_left.value.addEventListener("touchend", onTouchEndLeft);

    loadFootOutline("left", scene_left);
    console.log("Left scene children:", scene_left.children);
  }

  if (target_right.value) {
    const setupRight = createSceneAndRenderer(target_right.value);
    scene_right = setupRight.scene;
    camera_right = setupRight.camera;
    renderer_right = setupRight.renderer;
    controls_right = setupRight.controls;
    target_right.value.appendChild(renderer_right.domElement);
    console.log("Right scene created with lights:", scene_right.children.filter(c => c.isLight));

    // Add event listeners for right side.
    target_right.value.addEventListener("mousedown", onMouseDownRight);
    target_right.value.addEventListener("mousemove", onMouseMoveRight);
    target_right.value.addEventListener("mouseup", onMouseUpRight);
    target_right.value.addEventListener("touchstart", onTouchStartRight);
    target_right.value.addEventListener("touchmove", onTouchMoveRight);
    target_right.value.addEventListener("touchend", onTouchEndRight);

    loadFootOutline("right", scene_right);
    console.log("Right scene children:", scene_right.children);
  }

  animateBoth();
});

onBeforeUnmount(() => {
  if (target_left.value) {
    target_left.value.removeEventListener("mousedown", onMouseDownLeft);
    target_left.value.removeEventListener("mousemove", onMouseMoveLeft);
    target_left.value.removeEventListener("mouseup", onMouseUpLeft);

    target_left.value.removeEventListener("touchstart", onTouchStartLeft);
    target_left.value.removeEventListener("touchmove", onTouchMoveLeft);
    target_left.value.removeEventListener("touchend", onTouchEndLeft);
  }

  if (target_right.value) {
    target_right.value.removeEventListener("mousedown", onMouseDownRight);
    target_right.value.removeEventListener("mousemove", onMouseMoveRight);
    target_right.value.removeEventListener("mouseup", onMouseUpRight);

    target_right.value.removeEventListener("touchstart", onTouchStartRight);
    target_right.value.removeEventListener("touchmove", onTouchMoveRight);
    target_right.value.removeEventListener("touchend", onTouchEndRight);
  }
});

/* ------------------- FOOT OUTLINE & BOX LINES ------------------- */
function loadFootOutline(side, scene) {
  const outlineImg = (side === "left") ? outline_left : outline_right;
  const texLoader = new THREE.TextureLoader();
  texLoader.load(outlineImg, (texture) => {
    const planeGeo = new THREE.PlaneGeometry(150, 300);
    const planeMat = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
    });
    const footOutline = new THREE.Mesh(planeGeo, planeMat);
    footOutline.rotation.x = -Math.PI / 2;
    footOutline.position.set(0, 0.1, 0);
    scene.add(footOutline);
  });
}

/* ------------------- BUILD BOUNDING MESH & CHECK ------------------- */
function createCustomFootBoundingLines(side, scene) {
  // Define key points (adjust coordinates as needed)
  let midHeelLeft;
  let midHeelRight;
  let heelLeft;
  let heelRight;
  let middleBackLegLeft;
  let middleBackLegRight;
  let upperBackLegLeft;
  let upperBackLegRight;
  let upperFrontalLegLeft;
  let upperFrontalLegRight;
  let middleFrontalLegLeft;
  let middleFrontalLegRight;
  let upperMidFootLeft;
  let upperMidFootRight;
  let bottomBallLeft;
  let bottomBallRight;
  let upperBallLeft;
  let upperBallRight;
  let medial_point;
  let lateral_point;
  let bottomToesLeft;
  let bottomToesRight;
  let upperToesLeft;
  let upperToesRight;

  if (route?.params?.category?.includes("f")) {
    midHeelLeft = new THREE.Vector3(-40, 0.1, 80);
    midHeelRight = new THREE.Vector3(40, 0.1, 80);
    heelLeft = new THREE.Vector3(-40, 0.1, 150);
    heelRight = new THREE.Vector3(40, 0.1, 150);
    middleBackLegLeft = new THREE.Vector3(-50, 100, 150);
    middleBackLegRight = new THREE.Vector3(50, 100, 150);
    upperBackLegLeft = new THREE.Vector3(-50, 300, 150);
    upperBackLegRight = new THREE.Vector3(50, 300, 150);
    upperFrontalLegLeft = new THREE.Vector3(-50, 300, 35);
    upperFrontalLegRight = new THREE.Vector3(50, 300, 35);
    middleFrontalLegLeft = new THREE.Vector3(-50, 120, 35);
    middleFrontalLegRight = new THREE.Vector3(50, 120, 35);
    upperMidFootLeft = new THREE.Vector3(-50, 100, 15);
    upperMidFootRight = new THREE.Vector3(50, 100, 15);
    bottomBallLeft = side === "left"
        ? new THREE.Vector3(-65, 0.1, -31)
        : new THREE.Vector3(-65, 0.1, -63);
    bottomBallRight = side === "left"
        ? new THREE.Vector3(65, 0.1, -63)
        : new THREE.Vector3(65, 0.1, -31);
    upperBallLeft = side === "left"
        ? new THREE.Vector3(-65, 50, -31)
        : new THREE.Vector3(-65, 50, -63);
    upperBallRight = side === "left"
        ? new THREE.Vector3(65, 50, -63)
        : new THREE.Vector3(65, 50, -31);
    medial_point = side === "left" ? -140 : -160;
    lateral_point = side === "left" ? -160 : -140;
    bottomToesLeft = side === "left"
        ? new THREE.Vector3(-60, 0.1, medial_point)
        : new THREE.Vector3(-60, 0.1, medial_point);
    bottomToesRight = side === "left"
        ? new THREE.Vector3(60, 0.1, lateral_point)
        : new THREE.Vector3(60, 0.1, lateral_point);
    upperToesLeft = side === "left"
        ? new THREE.Vector3(-60, 30, medial_point)
        : new THREE.Vector3(-60, 35, medial_point);
    upperToesRight = side === "left"
        ? new THREE.Vector3(60, 35, lateral_point)
        : new THREE.Vector3(60, 30, lateral_point);
  } else if (route?.params?.category?.includes("m")) {
    midHeelLeft = new THREE.Vector3(-50, 0.1, 80);
    midHeelRight = new THREE.Vector3(50, 0.1, 80);
    heelLeft = new THREE.Vector3(-50, 0.1, 150);
    heelRight = new THREE.Vector3(50, 0.1, 150);
    middleBackLegLeft = new THREE.Vector3(-50, 100, 150);
    middleBackLegRight = new THREE.Vector3(50, 100, 150);
    upperBackLegLeft = new THREE.Vector3(-50, 300, 150);
    upperBackLegRight = new THREE.Vector3(50, 300, 150);
    upperFrontalLegLeft = new THREE.Vector3(-50, 300, 25);
    upperFrontalLegRight = new THREE.Vector3(50, 300, 25);
    middleFrontalLegLeft = new THREE.Vector3(-50, 140, 25);
    middleFrontalLegRight = new THREE.Vector3(50, 140, 25);
    upperMidFootLeft = new THREE.Vector3(-50, 120, 5);
    upperMidFootRight = new THREE.Vector3(50, 120, 5);
    bottomBallLeft = side === "left"
        ? new THREE.Vector3(-65, 0.1, -31)
        : new THREE.Vector3(-65, 0.1, -63);
    bottomBallRight = side === "left"
        ? new THREE.Vector3(65, 0.1, -63)
        : new THREE.Vector3(65, 0.1, -31);
    upperBallLeft = side === "left"
        ? new THREE.Vector3(-65, 50, -31)
        : new THREE.Vector3(-65, 50, -63);
    upperBallRight = side === "left"
        ? new THREE.Vector3(65, 50, -63)
        : new THREE.Vector3(65, 50, -31);
    medial_point = side === "left" ? -140 : -160;
    lateral_point = side === "left" ? -160 : -140;
    bottomToesLeft = side === "left"
        ? new THREE.Vector3(-60, 0.1, medial_point)
        : new THREE.Vector3(-60, 0.1, medial_point);
    bottomToesRight = side === "left"
        ? new THREE.Vector3(60, 0.1, lateral_point)
        : new THREE.Vector3(60, 0.1, lateral_point);
    upperToesLeft = side === "left"
        ? new THREE.Vector3(-60, 30, medial_point)
        : new THREE.Vector3(-60, 30, medial_point);
    upperToesRight = side === "left"
        ? new THREE.Vector3(60, 30, lateral_point)
        : new THREE.Vector3(60, 30, lateral_point);
  }
  // Build an ordered list of points for the horizontal boundaries.
  // For example, let’s assume we want the following chains:
  // • Left side: bottomToesLeft → upperToesLeft → upperBallLeft → bottomBallLeft → heelLeft
  // • Right side: bottomToesRight → upperToesRight → upperBallRight → bottomBallRight → heelRight
  // • Plus some cross-connections between the sides.
  const pts = [
    // left side connections
    upperFrontalLegLeft, middleFrontalLegLeft,
    middleFrontalLegLeft, upperMidFootLeft,
    upperMidFootLeft, upperBallLeft,
    upperBallLeft, upperToesLeft,
    upperToesLeft, bottomToesLeft,
    bottomToesLeft, bottomBallLeft,
    bottomBallLeft, heelLeft,
    heelLeft, middleBackLegLeft,
    middleBackLegLeft, upperBackLegLeft,
    upperBackLegLeft, upperFrontalLegLeft,
    // right side connections
    upperFrontalLegRight, middleFrontalLegRight,
    middleFrontalLegRight, upperMidFootRight,
    upperMidFootRight, upperBallRight,
    upperBallRight, upperToesRight,
    upperToesRight, bottomToesRight,
    bottomToesRight, bottomBallRight,
    bottomBallRight, heelRight,
    heelRight, middleBackLegRight,
    middleBackLegRight, upperBackLegRight,
    upperBackLegRight, upperFrontalLegRight,
    // horizontal lines (left to right (lateral to medial for left foot and medial to lateral for right foot) connections)
    bottomToesLeft, bottomToesRight,
    upperToesLeft, upperToesRight,
    upperBallLeft, upperBallRight,
    upperMidFootLeft, upperMidFootRight,
    middleFrontalLegLeft, middleFrontalLegRight,
    upperFrontalLegLeft, upperFrontalLegRight,
    upperBackLegLeft, upperBackLegRight,
    heelLeft, heelRight,
    midHeelLeft, midHeelRight,
    bottomBallLeft, bottomBallRight
  ];
  // Create line segments by connecting consecutive points (and closing the loop).
  const linePairs = [];
  for (let i = 0; i < pts.length; i = i + 2) {
    const start = pts[i];
    const end = pts[(i + 1) % pts.length];
    linePairs.push(start, end);
  }
  const positions = [];
  for (const pt of linePairs) {
    positions.push(pt.x, pt.y, pt.z);
  }
  const lineGeom = new THREE.BufferGeometry();
  lineGeom.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
  // Create a LineSegments object with a visible color.
  const lineMat = new THREE.LineBasicMaterial({color: 0xff0000});
  const wireframeLines = new THREE.LineSegments(lineGeom, lineMat);
  scene.add(wireframeLines);
  return wireframeLines;
}

// Helper: Extract endpoints (in order) from the LineSegments geometry.
// We assume that the geometry stores the endpoints sequentially.
function getWireframeEndpoints(lineSegments) {
  const posArray = lineSegments.geometry.attributes.position.array;
  const endpoints = [];
  // For a closed loop, we'll just extract one endpoint per segment.
  for (let i = 0; i < posArray.length; i += 3) {
    endpoints.push(new THREE.Vector3(posArray[i], posArray[i + 1], posArray[i + 2]));
  }
  return endpoints;
}

// Helper: Test whether a line segment (p0, p1) intersects a triangle (a, b, c).
function lineSegmentIntersectsTriangle(p0, p1, a, b, c) {
  const EPSILON = 1e-6;
  const d = new THREE.Vector3().subVectors(p1, p0);  // segment direction
  const edge1 = new THREE.Vector3().subVectors(b, a);
  const edge2 = new THREE.Vector3().subVectors(c, a);
  const h = new THREE.Vector3().crossVectors(d, edge2);
  const a_det = edge1.dot(h);
  if (a_det > -EPSILON && a_det < EPSILON) return false; // Parallel
  const f = 1.0 / a_det;
  const s = new THREE.Vector3().subVectors(p0, a);
  const u = f * s.dot(h);
  if (u < 0.0 || u > 1.0) return false;
  const q = new THREE.Vector3().crossVectors(s, edge1);
  const v = f * d.dot(q);
  if (v < 0.0 || u + v > 1.0) return false;
  const t = f * edge2.dot(q);
  // t must be within 0 and 1 for the intersection to lie on the segment
  if (t < 0.0 || t > 1.0) return false;
  return true;
}

// Main function: check if any triangle of footModel intersects any wireframe segment.
function doesMeshIntersectWireframe(footModel, wireframeEndpoints) {
  // Update transforms.
  footModel.updateMatrixWorld(true);
  const geom = footModel.geometry;
  if (!geom.attributes.position) {
    console.warn("Foot model has no position attribute.");
    return false;
  }
  const posAttr = geom.attributes.position;
  const count = geom.index ? geom.index.count : posAttr.count;
  // For easier iteration, get an array of indices if available.
  let indices = geom.index ? geom.index.array : null;
  const triCount = indices ? indices.length / 3 : posAttr.count / 3;
  // Pre-allocate vectors.
  const v0 = new THREE.Vector3(), v1 = new THREE.Vector3(), v2 = new THREE.Vector3();
  // For each triangle in the foot model:
  for (let i = 0; i < triCount; i++) {
    if (indices) {
      v0.set(
          posAttr.getX(indices[i * 3]),
          posAttr.getY(indices[i * 3]),
          posAttr.getZ(indices[i * 3])
      );
      v1.set(
          posAttr.getX(indices[i * 3 + 1]),
          posAttr.getY(indices[i * 3 + 1]),
          posAttr.getZ(indices[i * 3 + 1])
      );
      v2.set(
          posAttr.getX(indices[i * 3 + 2]),
          posAttr.getY(indices[i * 3 + 2]),
          posAttr.getZ(indices[i * 3 + 2])
      );
    } else {
      v0.set(
          posAttr.getX(i * 3),
          posAttr.getY(i * 3),
          posAttr.getZ(i * 3)
      );
      v1.set(
          posAttr.getX(i * 3 + 1),
          posAttr.getY(i * 3 + 1),
          posAttr.getZ(i * 3 + 1)
      );
      v2.set(
          posAttr.getX(i * 3 + 2),
          posAttr.getY(i * 3 + 2),
          posAttr.getZ(i * 3 + 2)
      );
    }
    // Transform triangle vertices to world coordinates.
    footModel.localToWorld(v0);
    footModel.localToWorld(v1);
    footModel.localToWorld(v2);
    // Now, iterate over each wireframe segment.
    // Assume wireframeEndpoints is an ordered array of vertices defining a closed loop.
    for (let j = 0; j < wireframeEndpoints.length; j++) {
      const p0 = wireframeEndpoints[j];
      const p1 = wireframeEndpoints[(j + 1) % wireframeEndpoints.length];
      if (lineSegmentIntersectsTriangle(p0, p1, v0, v1, v2)) {
        // If any triangle intersects any segment, the mesh touches the wireframe.
        return true;
      }
    }
  }
  // No intersections found.
  return false;
}

function isFootWithinLeftRightEdges(footModel, boundingBox) {
  const footBox = new THREE.Box3().setFromObject(footModel);
  if (footBox.min.x < boundingBox.min.x) return false;
  if (footBox.max.x > boundingBox.max.x) return false;
  // Otherwise, foot’s bounding box is within left & right edges
  return true;
}

function liveContinuousRotationForFitY(footModel, boundingBox, scene, callback) {
  // Save the starting transform.
  const originalPos = footModel.position.clone();
  const originalRot = footModel.rotation.clone();
  let totalRotation = 0; // total rotation in degrees
  const deltaAngle = 1;  // degrees to rotate per step
  // Array to record orientations when the mesh is valid.
  const validOrientations = [];
  // Track the last valid state (true if the previous step had the mesh inside).
  let lastValid = false;
  console.log("Starting continuous Y rotation search from current orientation");

  function doNextStep() {
    // If we've rotated a full 360° without a valid transition:
    if (totalRotation >= 360) {
      if (validOrientations.length > 0) {
        const midIndex = Math.floor(validOrientations.length / 2);
        const best = validOrientations[midIndex];
        console.log("Full rotation completed; using mid-orientation at totalRotation =", best.totalRotation, "°");
        footModel.position.copy(best.position);
        footModel.rotation.copy(best.rotation);
        footModel.updateMatrixWorld(true);
        callback(true);
      } else {
        console.warn("Full rotation completed with no valid orientation; reverting.");
        footModel.position.copy(originalPos);
        footModel.rotation.copy(originalRot);
        footModel.updateMatrixWorld(true);
        callback(false);
      }
      return;
    }
    // Increment the Y rotation.
    let deltaRad = THREE.MathUtils.degToRad(deltaAngle);
    deltaRad = deltaRad + 5;
    footModel.rotation.y += deltaRad;
    totalRotation += deltaAngle;
    footModel.updateMatrixWorld(true);
    // Re-center the model on the floor:
    const box = new THREE.Box3().setFromObject(footModel);
    const center = new THREE.Vector3();
    box.getCenter(center);
    footModel.position.sub(center);
    footModel.position.y = 0;
    footModel.updateMatrixWorld(true);
    // Check if the actual mesh (each vertex) is within the left/right boundaries.
    const isValid = isFootWithinLeftRightEdges(footModel, boundingBox);
    console.log(`TotalRotation=${totalRotation}°: isValid=${isValid}`);
    // If the current orientation is valid, record it.
    if (isValid) {
      validOrientations.push({
        position: footModel.position.clone(),
        rotation: footModel.rotation.clone(),
        totalRotation: totalRotation
      });
    }
    // If we had a valid state and now the state is invalid, then the valid interval ended.
    if (lastValid && !isValid && validOrientations.length > 0) {
      const midIndex = Math.floor(validOrientations.length / 2);
      const best = validOrientations[midIndex];
      console.log("Transition detected (from valid to invalid) at totalRotation =", totalRotation, "°.");
      console.log("Selecting mid-orientation at totalRotation =", best.totalRotation, "°.");
      footModel.position.copy(best.position);
      footModel.rotation.copy(best.rotation);
      footModel.updateMatrixWorld(true);
      callback(true);
      return;
    }
    // Update the last valid state.
    lastValid = isValid;
    //doNextStep();
    // Continue to next step (delay can be adjusted; here using 50ms).
    setTimeout(() => {
      requestAnimationFrame(doNextStep);
    }, 250);
  }

  doNextStep();
}

function ensureModelAboveGrid(footModel) {
  // Ensure the world matrix is updated.
  footModel.updateMatrixWorld(true);
  // Retrieve the position attribute of the geometry.
  const posAttr = footModel.geometry.attributes.position;
  if (!posAttr) {
    console.warn("No position attribute found on the geometry.");
    return;
  }
  let minY = Infinity;
  const localVertex = new THREE.Vector3();
  const worldVertex = new THREE.Vector3();
  // Loop over each vertex.
  for (let i = 0; i < posAttr.count; i++) {
    localVertex.set(posAttr.getX(i), posAttr.getY(i), posAttr.getZ(i));
    // Convert from local to world coordinates.
    worldVertex.copy(localVertex);
    footModel.localToWorld(worldVertex);
    if (worldVertex.y < minY) {
      minY = worldVertex.y;
    }
  }
  // If any vertex is below y=0, shift the model upward by the negative amount.
  if (minY < 0) {
    //console.log("Lowest vertex y:", minY, "shifting model upward by", -minY);
    footModel.position.y -= minY;  // shift up so that the lowest vertex is at y=0
    footModel.updateMatrixWorld(true);
  }
}

function nudgeModelToBackBoundaryVertices(footModel, wireframeEndpoints, tolerance = 0.1) {
  // Determine the back boundary of the wireframe from its endpoints.
  let backBoundary = -Infinity;
  for (const pt of wireframeEndpoints) {
    if (pt.z > backBoundary) {
      backBoundary = (pt.z);
    }
  }
  // Update world transform of the foot model.
  footModel.updateMatrixWorld(true);
  // Get the foot model's geometry and iterate over all vertices to compute the maximum z.
  const geometry = footModel.geometry;
  if (!geometry.attributes.position) {
    console.warn("Foot model has no position attribute");
    return false;
  }
  const posAttr = geometry.attributes.position;
  let modelMaxZ = -Infinity;
  const localV = new THREE.Vector3();
  const worldV = new THREE.Vector3();
  for (let i = 0; i < posAttr.count; i++) {
    localV.set(
        posAttr.getX(i),
        posAttr.getY(i),
        posAttr.getZ(i)
    );
    // Transform the vertex to world coordinates.
    footModel.localToWorld(worldV.copy(localV));
    if (worldV.z > modelMaxZ) {
      modelMaxZ = worldV.z;
    }
  }
  // Check if the difference exceeds tolerance.
  if (Math.abs(backBoundary - modelMaxZ) > tolerance) {
    const deltaZ = backBoundary - modelMaxZ;
    //console.log("Nudging model in z by", deltaZ, "to align with back boundary", backBoundary);
    footModel.position.z += deltaZ;
    footModel.updateMatrixWorld(true);
    return true;
  }
  return false;
}

function liveContinuousRotationForFitX(footModel, wireframeEndpoints, scene, callback) {
  // Save the starting transform.
  const originalPos = footModel.position.clone();
  const originalRot = footModel.rotation.clone();
  let totalRotation = 0; // in degrees
  const deltaAngle = 5;  // degrees to rotate per step
  // Array to record valid (non-intersecting) orientations.
  const validOrientations = [];
  // We'll record the last intersection state to detect transitions.
  // Assume we start intersecting; if not, the very first step will record an orientation.
  let lastIntersecting = true;
  console.log("Starting continuous X rotation search from current orientation");

  function doNextStep() {
    // If we've rotated a full 360° and haven't ended a valid interval, stop.
    if (totalRotation >= 360) {
      if (validOrientations.length > 0) {
        const midIndex = Math.floor(validOrientations.length - 1);
        const best = validOrientations[midIndex];
        console.log("Full rotation completed; using mid-orientation at angle", best.totalRotation, "°");
        footModel.position.copy(best.position);
        footModel.rotation.copy(best.rotation);
        footModel.updateMatrixWorld(true);
        callback(true);
      } else {
        console.warn("Full rotation completed with no valid orientation; reverting.");
        footModel.position.copy(originalPos);
        footModel.rotation.copy(originalRot);
        footModel.updateMatrixWorld(true);
        callback(false);
      }
      return;
    }
    // Increment the X rotation.
    const deltaRad = THREE.MathUtils.degToRad(deltaAngle);
    footModel.rotation.x += deltaRad;
    totalRotation += deltaAngle;
    footModel.updateMatrixWorld(true);
    // Re-center the model.
    const tempBox = new THREE.Box3().setFromObject(footModel);
    const center = new THREE.Vector3();
    tempBox.getCenter(center);
    footModel.position.sub(center);
    // Ensure the actual vertices are above the grid.
    ensureModelAboveGrid(footModel);
    nudgeModelToBackBoundaryVertices(footModel, wireframeEndpoints, 0.1);
    footModel.updateMatrixWorld(true);
    // Check if the model's mesh does NOT intersect any wireframe segment.
    const currentlyIntersecting = doesMeshIntersectWireframe(footModel, wireframeEndpoints);
    //console.log(`TotalRotation=${totalRotation}°: intersecting = ${currentlyIntersecting}`);
    // If the model is not intersecting, record this orientation.
    if (!currentlyIntersecting) {
      validOrientations.push({
        position: footModel.position.clone(),
        rotation: footModel.rotation.clone(),
        totalRotation: totalRotation
      });
    }
    // Detect a transition: if last frame was non-intersecting and now it is intersecting,
    // then the non-intersecting period has just ended.
    if (!lastIntersecting && currentlyIntersecting && validOrientations.length > 0) {
      // Use the midpoint of the recorded valid orientations.
      const midIndex = Math.floor(validOrientations.length - 1);
      const best = validOrientations[midIndex];
      footModel.position.copy(best.position);
      footModel.rotation.copy(best.rotation);
      footModel.updateMatrixWorld(true);
      callback(true);
      return;
    }

    // Update last state.
    lastIntersecting = currentlyIntersecting;
    //doNextStep();
    // Wait 300ms before next step.
    setTimeout(() => {
      requestAnimationFrame(doNextStep);
    }, 250);
  }

  doNextStep();
}

function flipFootModelIfBelowGrid(footModel) {
  // Update world matrix so bounding box is accurate
  footModel.updateMatrixWorld(true);
  const footBox = new THREE.Box3().setFromObject(footModel);
  // If the *lowest* point is below y=0, we flip
  if (footBox.min.y < 0) {
    console.log("Some or all of the foot is below the grid => rotating 180° around Z.");
    footModel.rotateZ(Math.PI);
    footModel.updateMatrixWorld(true);
  }
}

function centerMeshInBoxZ(footModel, boundingBox) {
  // boundingBox is an object with .min and .max (THREE.Vector3)
  // that define the desired z-boundaries (e.g., -100 to 100).

  // 1) Update the model's world matrix so bounding box calculations are correct.
  footModel.updateMatrixWorld(true);

  const geometry = footModel.geometry;
  if (!geometry.attributes.position) {
    console.warn("Foot model has no position attribute.");
    return;
  }

  // 2) Determine the actual min and max Z of the model in world space.
  const posAttr = geometry.attributes.position;
  let minZ = Infinity;
  let maxZ = -Infinity;
  const localV = new THREE.Vector3();
  const worldV = new THREE.Vector3();

  for (let i = 0; i < posAttr.count; i++) {
    localV.set(posAttr.getX(i), posAttr.getY(i), posAttr.getZ(i));
    footModel.localToWorld(worldV.copy(localV));
    if (worldV.z < minZ) minZ = worldV.z;
    if (worldV.z > maxZ) maxZ = worldV.z;
  }

  // 3) We want the front (minZ) and back (maxZ) of the model
  // to be within [boundingBox.min.z, boundingBox.max.z], e.g. [-100, 100].
  const modelSpan = maxZ - minZ;
  const boxSpan = boundingBox.max.z - boundingBox.min.z;

  // If the model is bigger than the bounding range, we still center it, but it may exceed.
  // At least we try to shift it so its midpoint is aligned with boundingBox's midpoint.

  // Compute the model's midpoint along Z.
  const modelMidZ = (minZ + maxZ) * 0.5;
  // Compute boundingBox midpoint along Z.
  const boxMidZ = (boundingBox.min.z + boundingBox.max.z) * 0.5;

  // Shift so that the model's center matches the boundingBox center.
  const shiftZ = boxMidZ - modelMidZ;

  footModel.position.z += shiftZ;
  footModel.updateMatrixWorld(true);

  // 4) After the shift, clamp if needed to ensure minZ >= boundingBox.min.z and maxZ <= boundingBox.max.z.
  // Recompute minZ, maxZ in case we shifted.
  minZ = Infinity;
  maxZ = -Infinity;

  for (let i = 0; i < posAttr.count; i++) {
    localV.set(posAttr.getX(i), posAttr.getY(i), posAttr.getZ(i));
    footModel.localToWorld(worldV.copy(localV));
    if (worldV.z < minZ) minZ = worldV.z;
    if (worldV.z > maxZ) maxZ = worldV.z;
  }

  // If the front is still too far forward, push it back.
  if (minZ < boundingBox.min.z) {
    const diff = boundingBox.min.z - minZ;
    footModel.position.z += diff;
  }

  // If the back is still too far backward, push it forward.
  if (maxZ > boundingBox.max.z) {
    const diff = boundingBox.max.z - maxZ;
    footModel.position.z += diff;
  }

  // Final update
  footModel.updateMatrixWorld(true);
}

function removeCustomFootBoundingLines(wireframeLines, scene) {
  if (!wireframeLines) return;
  scene.remove(wireframeLines);
  if (wireframeLines.geometry) {
    wireframeLines.geometry.dispose();
  }
  if (wireframeLines.material) {
    if (Array.isArray(wireframeLines.material)) {
      wireframeLines.material.forEach(mat => mat.dispose());
    } else {
      wireframeLines.material.dispose();
    }
  }
}

function applyClippingPlaneAtHeight(mesh, height) {
  // Create a clipping plane that clips away any fragments above 'height'.
  // Here, we want to keep the model below height 150, so we clip for p.y > 150.
  // We choose a plane with a normal pointing downward (0, -1, 0) and constant equal to height.
  const clippingPlane = new THREE.Plane(new THREE.Vector3(0, -1, 0), height);
  // Assign the clipping plane to the mesh material.
  // If your mesh uses a single material:
  if (Array.isArray(mesh.material)) {
    // If the mesh uses an array of materials, assign the clipping plane to each one.
    mesh.material.forEach(mat => {
      mat.clippingPlanes = [clippingPlane];
      // Optionally, set mat.clipShadows = true; if you want the shadows to be clipped as well.
    });
  } else {
    mesh.material.clippingPlanes = [clippingPlane];
    // Optionally:
    mesh.material.clipShadows = true;
  }
  // (Optional) If you wish the mesh to render in wireframe for visualization:
  mesh.material.wireframe = false;
  // Ensure the material is flagged for clipping.
  mesh.material.needsUpdate = true;
}

/**
 * liveRotateBackwardSingleLineGridCheckWithDebugPivot
 *
 * Rotates the foot model (via its pivot group) about the x‑axis in small increments.
 * It checks a ray cast along the heel line (from heelA to heelB) to see whether the model
 * is intersecting that line. If the model is initially intersecting, the function rotates
 * in one direction until the intersection disappears; if it is initially not intersecting,
 * it rotates in the opposite direction until an intersection occurs.
 *
 * The function stops as soon as the intersection state changes from the initial state (or if
 * a maximum rotation is reached), then calls doneCallback with true if a transition was detected.
 *
 * @param {THREE.Object3D} pivotGroup - The group that serves as the pivot (its position should be at the desired pivot point).
 * @param {THREE.Object3D} footModel - The foot model (child of pivotGroup).
 * @param {THREE.Vector3} heelA - World-space start point of the heel line.
 * @param {THREE.Vector3} heelB - World-space end point of the heel line.
 * @param {THREE.Scene} scene - The scene (optional; for debug logging or visualization).
 * @param {number} [stepAngleDeg=0.5] - Degrees to rotate per step.
 * @param {number} [maxAngleDeg=20] - Maximum total rotation (in degrees) allowed.
 * @param {Function} doneCallback - Called when the rotation stops. (Argument is true if state changed before maxAngle.)
 */
function liveRotateBackwardSingleLineGridCheckWithDebugPivot(
    pivotGroup,
    footModel,
    heelA,
    heelB,
    scene,
    stepAngleDeg = 0.5,
    maxAngleDeg = 20,
    doneCallback
) {
  let totalRotation = 0;
  const stepRad = THREE.MathUtils.degToRad(stepAngleDeg);

  // Determine the initial state: does the foot mesh intersect the segment?
  const initialIntersection = getSegmentIntersectionWorldSpace(footModel, heelA, heelB);
  const initialState = (initialIntersection !== null);
  console.log(
      "Initial intersection state:",
      initialState ? "INTERSECTING" : "NOT INTERSECTING"
  );

  function step() {
    // Check current intersection state.
    const currentIntersection = getSegmentIntersectionWorldSpace(footModel, heelA, heelB);
    const currentState = (currentIntersection !== null);
    console.log(
        `Total rotation ${totalRotation}°: current state =`,
        currentState ? "INTERSECTING" : "NOT INTERSECTING"
    );

    // If the current state is different from the initial state, stop.
    if (currentState !== initialState) {
      console.log("State transition detected at total rotation:", totalRotation, "°");
      doneCallback(true);
      return;
    }

    // If we've rotated the maximum allowed angle, stop.
    if (totalRotation >= maxAngleDeg) {
      console.warn("Max rotation reached without state change.");
      doneCallback(false);
      return;
    }

    // Determine rotation direction:
    // If the model is initially intersecting, we want to rotate upward (positive x‑rotation)
    // so that the intersection stops. Otherwise, if not intersecting, rotate downward (negative).
    const rotationDirection = initialState ? -1 : 1;

    // Increment the rotation.
    pivotGroup.rotation.x += rotationDirection * stepRad;
    totalRotation += stepAngleDeg;
    pivotGroup.updateMatrixWorld(true);
    footModel.updateMatrixWorld(true);

    // Continue after a short delay.
    //setTimeout(() => {
    //  requestAnimationFrame(step);
    //}, 300);
    step();
  }

  step();
}

function liveRotateForwardSingleLineGridCheckWithDebugPivot(
    pivotGroup,
    footModel,
    ballA,
    ballB,
    scene,
    stepAngleDeg = 0.5,
    maxAngleDeg = 20,
    doneCallback
) {
  let totalRotation = 0;
  const stepRad = THREE.MathUtils.degToRad(stepAngleDeg);

  // Determine the initial state: does the foot mesh intersect the segment?
  const initialIntersection = getSegmentIntersectionWorldSpace(footModel, ballA, ballB);
  const initialState = (initialIntersection !== null);
  console.log(
      "Initial intersection state:",
      initialState ? "INTERSECTING" : "NOT INTERSECTING"
  );

  function step() {
    // Check current intersection state.
    const currentIntersection = getSegmentIntersectionWorldSpace(footModel, ballA, ballB);
    const currentState = (currentIntersection !== null);
    console.log(
        `Total rotation ${totalRotation}°: current state =`,
        currentState ? "INTERSECTING" : "NOT INTERSECTING"
    );

    // If the current state is different from the initial state, stop.
    if (currentState !== initialState) {
      console.log("State transition detected at total rotation:", totalRotation, "°");
      doneCallback(true);
      return;
    }

    // If we've rotated the maximum allowed angle, stop.
    if (totalRotation >= maxAngleDeg) {
      console.warn("Max rotation reached without state change.");
      doneCallback(false);
      return;
    }

    // Determine rotation direction:
    // If the model is initially intersecting, we want to rotate upward (positive x‑rotation)
    // so that the intersection stops. Otherwise, if not intersecting, rotate downward (negative).
    const rotationDirection = initialState ? 1 : -1;

    // Increment the rotation.
    pivotGroup.rotation.x += rotationDirection * stepRad;
    totalRotation += stepAngleDeg;
    pivotGroup.updateMatrixWorld(true);
    footModel.updateMatrixWorld(true);

    // Continue after a short delay.
    //setTimeout(() => {
    //  requestAnimationFrame(step);
    //}, 300);
    step();
  }

  step();
}

function getSegmentIntersectionWorldSpace(footModel, start, end) {
  const direction = end.clone().sub(start);
  const length = direction.length();
  direction.normalize();
  const raycaster = new THREE.Raycaster(start, direction);
  // Intersect footModel
  const intersects = raycaster.intersectObject(footModel, true);
  if (intersects.length === 0) {
    return null;
  }
  // Check if the intersection is within [0, length]
  for (const hit of intersects) {
    if (hit.distance <= length) {
      // The intersection is within the segment
      console.log("Intersecting at: " + hit.point)
      return hit.point.clone();
    }
  }
  return null;
}

function drawLineInScene(scene, start, end, color = 0xffffff) {
  const geometry = new THREE.BufferGeometry().setFromPoints([start, end]);
  const material = new THREE.LineBasicMaterial({color});
  const line = new THREE.Line(geometry, material);
  scene.add(line);
  return line;
}

function disposeLine(line) {
  if (line.geometry) line.geometry.dispose();
  if (line.material) line.material.dispose();
}

function loadSTL(side, file, filename) {
  const {
    scene,
    widthRef,
    circumferenceRef,
    lengthRef
  } = (side === "left")
      ? {
        scene: scene_left,
        widthRef: width_left,
        circumferenceRef: circumference_left,
        lengthRef: length_left
      }
      : {
        scene: scene_right,
        widthRef: width_right,
        circumferenceRef: circumference_right,
        lengthRef: length_right
      };

  // 1) Detect file extension
  const extension = filename?.toLowerCase().split('.').pop();
  let loader;
  let isOBJ = false;

  // 2) Choose loader based on extension
  if (extension === 'stl') {
    loader = new STLLoader();
  } else if (extension === 'ply') {
    loader = new PLYLoader();
  } else if (extension === 'obj') {
    loader = new OBJLoader();
    isOBJ = true;
  } else {
    console.warn("Unknown extension, defaulting to STL loader");
    loader = new STLLoader();
  }

  // 3) Read file as text for OBJ, otherwise array buffer
  const reader = new FileReader();
  if (isOBJ) {
    reader.readAsText(file);  // OBJ is ASCII/text
  } else {
    reader.readAsArrayBuffer(file); // STL/PLY are typically binary
  }

  // 4) When the file has loaded, parse and build your foot model
  reader.onload = (e) => {
    let contents = e.target.result;

    // If OBJ, 'contents' is a string
    // If STL/PLY, 'contents' is an ArrayBuffer
    if (isOBJ) {
      contents = contents.toString(); // ensure it's a text string
    }

    let footModel;
    if (isOBJ) {
      // OBJLoader returns a Group (or Scene-like structure)
      const object3D = loader.parse(contents);
      // Assign a material and compute normals for each mesh child
      object3D.traverse((child) => {
        if (child.isMesh) {
          child.geometry.computeVertexNormals();
          child.material = new THREE.MeshPhongMaterial({
            color: 0x909090,
            shininess: 50,
            transparent: true,
            opacity: 0.8,
          });
        }
      });
      footModel = object3D;
    } else {
      // STL/PLY case: parse into a geometry
      const geometry = loader.parse(contents);
      geometry.computeVertexNormals();
      const material = new THREE.MeshPhongMaterial({
        color: 0x909090,
        shininess: 50,
        transparent: true,
        opacity: 0.8,
      });
      footModel = new THREE.Mesh(geometry, material);
    }

    // 5) If your filenames indicate we need a 1000x scaling:
    if (filename.includes("_FittrScanExport_")) {
      footModel.scale.set(1000, 1000, 1000);
    }

    footModel.castShadow = true;
    footModel.receiveShadow = true;

    // 6) Create bounding lines or wireframe skeleton for that side
    const wireframeLines = createCustomFootBoundingLines(side, scene);
    const wireframeEndpoints = getWireframeEndpoints(wireframeLines);

    // 7) Add foot model to the scene
    scene.add(footModel);
    loading_text.value = 'Aligning foot properly...';
    footModel.position.y = 0;
    footModel.updateMatrixWorld(true);

    // Flip it if necessary
    flipFootModelIfBelowGrid(footModel);

    // 8) Run your existing discrete / continuous rotation logic
    const boundingMeshBox = new THREE.Box3().setFromObject(wireframeLines);
    const boundingBox = {
      min: boundingMeshBox.min.clone(),
      max: boundingMeshBox.max.clone()
    };

    // Step 1: Y rotation
    liveContinuousRotationForFitY(footModel, boundingBox, scene, (ySuccess) => {
      if (!ySuccess) {
        console.warn("Skipping Step 2, since foot didn't fit in Step 1");
        return;
      }
      loading_text.value = 'Aligning foot properly...';

      // Step 2: X rotation
      liveContinuousRotationForFitX(
          footModel,
          wireframeEndpoints,
          scene,
          (success) => {
            if (success) {
              // Example pivot logic, aligning the foot around the "ball line"
              const ballPivotWorld = new THREE.Vector3(-65, 0, -50);
              const pivotGroup = new THREE.Object3D();
              pivotGroup.position.copy(ballPivotWorld);
              scene.add(pivotGroup);

              // Compute footModel's current world position/rotation:
              const footWorldPos = new THREE.Vector3();
              footModel.getWorldPosition(footWorldPos);
              const footWorldQuat = new THREE.Quaternion();
              footModel.getWorldQuaternion(footWorldQuat);

              // Remove from scene, attach to pivotGroup
              scene.remove(footModel);
              pivotGroup.add(footModel);

              // Reset footModel’s local transform
              footModel.position.set(0, 0, 0);
              footModel.quaternion.set(0, 0, 0, 1);
              pivotGroup.updateMatrixWorld(true);
              footModel.updateMatrixWorld(true);

              // Convert world position to pivotGroup local
              pivotGroup.worldToLocal(footWorldPos);
              footModel.position.copy(footWorldPos);

              // Convert world rotation to pivotGroup local
              const pivotInverseRot = new THREE.Quaternion()
                  .copy(pivotGroup.quaternion)
                  .invert();
              footModel.quaternion.copy(
                  pivotInverseRot.multiply(footWorldQuat)
              );

              // Now do the heel alignment:
              const heelA = new THREE.Vector3(-65, 0.1, 100);
              const heelB = new THREE.Vector3(65, 0.1, 100);

              liveRotateBackwardSingleLineGridCheckWithDebugPivot(
                  pivotGroup,
                  footModel,
                  heelA,
                  heelB,
                  scene,
                  0.5,
                  20,
                  (heelSuccess) => {
                    if (!heelSuccess) {
                      console.log("Heel alignment rotation failed.");
                      return;
                    }
                    console.log("Heel alignment rotation succeeded.");

                    // Then re-pivot for ball alignment if desired
                    const ballPivotWorld2 = new THREE.Vector3(-65, 0, 100);
                    const pivotGroup2 = new THREE.Object3D();
                    pivotGroup2.position.copy(ballPivotWorld2);
                    scene.add(pivotGroup2);

                    const footWorldPos2 = new THREE.Vector3();
                    footModel.getWorldPosition(footWorldPos2);
                    const footWorldQuat2 = new THREE.Quaternion();
                    footModel.getWorldQuaternion(footWorldQuat2);

                    scene.remove(footModel);
                    pivotGroup2.add(footModel);

                    footModel.position.set(0, 0, 0);
                    footModel.quaternion.set(0, 0, 0, 1);
                    pivotGroup2.updateMatrixWorld(true);
                    footModel.updateMatrixWorld(true);

                    pivotGroup2.worldToLocal(footWorldPos2);
                    footModel.position.copy(footWorldPos2);

                    const pivotInverseRot2 = new THREE.Quaternion()
                        .copy(pivotGroup2.quaternion)
                        .invert();
                    footModel.quaternion.copy(
                        pivotInverseRot2.multiply(footWorldQuat2)
                    );

                    // Ball alignment
                    const ballA = new THREE.Vector3(-65, 0.1, -50);
                    const ballB = new THREE.Vector3(65, 0.1, -50);

                    liveRotateForwardSingleLineGridCheckWithDebugPivot(
                        pivotGroup2,
                        footModel,
                        ballA,
                        ballB,
                        scene,
                        0.5,
                        20,
                        (ballSuccess) => {
                          if (ballSuccess) {
                            console.log("Ball alignment rotation succeeded.");
                            // Possibly apply a clipping plane
                            applyClippingPlaneAtHeight(footModel, 100);
                            // Then center along Z if needed:
                            centerMeshInBoxZ(footModel, boundingBox);
                          } else {
                            console.log("Ball alignment rotation failed.");
                          }
                        }
                    );
                  }
              );
            } else {
              console.warn("No orientation found or it ended early.");
            }
          }
      );
    });

    // 9) If we're loading left foot, store reference; likewise for right foot
    if (side === "left") {
      footModel_left = footModel;
      files_loaded_left.value = true;
    } else {
      footModel_right = footModel;
      files_loaded_right.value = true;
    }

    // Remove bounding lines from scene
    removeCustomFootBoundingLines(wireframeLines, scene);

    // 10) Measure foot, store measurements
    removeSpheresAndMeasurements(scene);
    const measurements = measureFoot(footModel, scene, side);
    widthRef.value = measurements.width;
    circumferenceRef.value = measurements.circumference;
    lengthRef.value = measurements.length;

    visible.value = false; // Hide any loading UI, etc.
  };
}

/* ------------------- MEASUREMENT LOGIC ------------------- */
function removeSpheresAndMeasurements(sceneRef) {
  sceneRef.children.slice().forEach((child) => {
    if (child.geometry && child.geometry.type === "SphereGeometry") {
      sceneRef.remove(child);
    }
  });
}

function measureFoot(footModel, scene, side) {
  if (!footModel) {
    console.warn("No foot model loaded.");
    return {width: 0, circumference: 0, length: 0};
  }

  // Remove any existing measurement spheres from the scene
  scene.children.slice().forEach((child) => {
    if (child.geometry && child.geometry.type === "SphereGeometry") {
      scene.remove(child);
    }
  });

  // Helper to draw spheres for debugging/visualizing measurements
  function drawSphere(position, color = 0xff0000) {
    const sphere = new THREE.Mesh(
        new THREE.SphereGeometry(1, 8, 8),
        new THREE.MeshBasicMaterial({ color })
    );
    sphere.position.copy(position);
    scene.add(sphere);
  }

  // --------------------------------------------------------
  // 1) Collect all vertices in WORLD SPACE from footModel
  //    whether footModel is a single Mesh or a Group
  // --------------------------------------------------------
  const worldVertices = [];

  // We traverse the entire footModel hierarchy:
  footModel.traverse((child) => {
    if (child.isMesh && child.geometry && child.geometry.attributes?.position) {
      const positionAttribute = child.geometry.attributes.position;
      // For each vertex in this child's geometry:
      for (let i = 0; i < positionAttribute.count; i++) {
        const localVertex = new THREE.Vector3(
            positionAttribute.getX(i),
            positionAttribute.getY(i),
            positionAttribute.getZ(i)
        );
        // Convert from the child's local space to world space
        const worldVertex = localVertex.applyMatrix4(child.matrixWorld);
        worldVertices.push(worldVertex);
      }
    }
  });

  // If we somehow collected no vertices, return 0
  if (worldVertices.length === 0) {
    console.warn("No vertices found in foot model (empty geometry?).");
    return { width: 0, circumference: 0, length: 0 };
  }

  // --------------------------------------------------------
  // 2) Find the widest points
  //    (Same logic as your original code, scanning from edges)
  // --------------------------------------------------------
  // Define measurement reference line for each foot
  const horizontalLineStart =
      side === "left" ? { x: -100, z: -25 } : { x: -100, z: -68 };
  const horizontalLineEnd =
      side === "left" ? { x: 100, z: -68 } : { x: 100, z: -25 };

  const inwardStep = 1.0;
  const heightStep = 1.0;
  const maxHeight = 50;
  const startHeight = 0;
  const tolerance = 1.0;

  // Left foot vs. right foot scanning logic
  let leftFactor, rightFactor;
  if (side === "left") {
    leftFactor = 0;
    rightFactor = 1;
  } else {
    // Invert scanning direction for the right foot
    leftFactor = 1;
    rightFactor = 0;
  }

  function interpolateZ(x) {
    const factor =
        (x - horizontalLineStart.x) /
        (horizontalLineEnd.x - horizontalLineStart.x);
    return (
        horizontalLineStart.z +
        factor * (horizontalLineEnd.z - horizontalLineStart.z)
    );
  }

  // Attempts to find the first vertex near (x, z) from y = startHeight to maxHeight
  function findVertex(x, z) {
    for (let y = startHeight; y <= maxHeight; y += heightStep) {
      for (const worldVertex of worldVertices) {
        if (
            Math.abs(worldVertex.x - x) <= tolerance &&
            Math.abs(worldVertex.z - z) <= tolerance &&
            Math.abs(worldVertex.y - y) <= tolerance
        ) {
          return worldVertex;
        }
      }
    }
    return null;
  }

  let widestLeftPoint = null;
  let widestRightPoint = null;

  // Scanning loop to find the leftmost & rightmost "widest" points
  while (true) {
    const leftX =
        horizontalLineStart.x +
        leftFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const leftZ = interpolateZ(leftX);

    const rightX =
        horizontalLineStart.x +
        rightFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const rightZ = interpolateZ(rightX);

    if (!widestLeftPoint) widestLeftPoint = findVertex(leftX, leftZ);
    if (!widestRightPoint) widestRightPoint = findVertex(rightX, rightZ);

    if (widestLeftPoint && widestRightPoint) break;

    // Adjust factors based on side
    if (side === "left") {
      // Original logic
      if (!widestLeftPoint) leftFactor += inwardStep / 200;
      if (!widestRightPoint) rightFactor -= inwardStep / 200;
      // Safety break
      if (leftFactor > 1 || rightFactor < 0) break;
    } else {
      // Right foot logic: invert direction
      if (!widestLeftPoint) leftFactor -= inwardStep / 200;
      if (!widestRightPoint) rightFactor += inwardStep / 200;
      // Safety break
      if (leftFactor < 0 || rightFactor > 1) break;
    }
  }

  if (!widestLeftPoint || !widestRightPoint) {
    console.warn("Unable to find widest points.");
    return { width: 0, circumference: 0, length: 0 };
  }

  // Draw spheres at the widest points (for debugging)
  drawSphere(widestLeftPoint, 0xff0000);
  drawSphere(widestRightPoint, 0x0000ff);

  // The "width" is the distance between these widest points
  const width = widestLeftPoint.distanceTo(widestRightPoint);

  // --------------------------------------------------------
  // 3) Measure circumference (topMarkers / bottomMarkers)
  // --------------------------------------------------------
  const stepSize = 1;       // horizontal step
  const yStep = 1;          // vertical step
  const maxDistance = 150;  // limit for searching up/down
  const toleranceC = 1.0;

  // Move up or down from a startPoint until we find a vertex on the surface
  function findMarkerOnModelSurface(startPoint, direction) {
    for (let offset = 0; offset <= maxDistance; offset += yStep) {
      const testY = startPoint.y + direction * offset;
      for (const vertex of worldVertices) {
        if (
            Math.abs(vertex.x - startPoint.x) <= toleranceC &&
            Math.abs(vertex.z - startPoint.z) <= toleranceC &&
            Math.abs(vertex.y - testY) <= toleranceC
        ) {
          return new THREE.Vector3(vertex.x, vertex.y, vertex.z);
        }
      }
    }
    return null;
  }

  const distanceBetweenWidestPoints = widestLeftPoint.distanceTo(widestRightPoint);
  const stepCount = Math.floor(distanceBetweenWidestPoints / stepSize);

  const topMarkers = [];
  const bottomMarkers = [];

  for (let i = 0; i <= stepCount; i++) {
    const t = i / stepCount;
    // Linear interpolation between widestLeftPoint & widestRightPoint
    const startPoint = new THREE.Vector3().lerpVectors(
        widestLeftPoint,
        widestRightPoint,
        t
    );

    const markerAbove = findMarkerOnModelSurface(startPoint, +1);
    const markerBelow = findMarkerOnModelSurface(startPoint, -1);

    if (markerAbove) {
      topMarkers.push(markerAbove);
      drawSphere(markerAbove, 0x00ff00); // green
    }
    if (markerBelow) {
      bottomMarkers.push(markerBelow);
      drawSphere(markerBelow, 0x0000ff); // blue
    }
  }

  function measureLineLength(markers) {
    if (markers.length < 2) return 0;
    let length = 0;
    for (let i = 0; i < markers.length - 1; i++) {
      length += markers[i].distanceTo(markers[i + 1]);
    }
    return length;
  }

  const topLineLength = measureLineLength(topMarkers);
  const bottomLineLength = measureLineLength(bottomMarkers);
  const circumference = topLineLength + bottomLineLength;

  // --------------------------------------------------------
  // 4) Measure the "length" of the foot
  //    (Your original measureModelLengthWithLines logic)
  // --------------------------------------------------------
  function measureModelLengthWithLines() {
    // 1) Filter out vertices above y=100
    const filteredVerts = worldVertices.filter((v) => v.y <= 100);
    if (filteredVerts.length === 0) {
      console.warn("No vertices found at or below y=100. Returning length=0.");
      return 0;
    }

    // 2) Among the remaining vertices, find minZ & maxZ
    let minZ = Infinity;
    let maxZ = -Infinity;
    for (const vertex of filteredVerts) {
      if (vertex.z < minZ) minZ = vertex.z;
      if (vertex.z > maxZ) maxZ = vertex.z;
    }

    const tolerance = 1.0;
    const stepSize = 1.0;
    let forwardLineZ = maxZ + 10;
    let backwardLineZ = minZ - 10;
    let forwardContactPoint = null;
    let backwardContactPoint = null;

    function findLineContact(z) {
      let farthestTop = null;
      let farthestBottom = null;
      for (const vertex of filteredVerts) {
        if (Math.abs(vertex.z - z) <= tolerance) {
          if (!farthestTop || vertex.y > farthestTop.y) farthestTop = vertex;
          if (!farthestBottom || vertex.y < farthestBottom.y)
            farthestBottom = vertex;
        }
      }
      return { farthestTop, farthestBottom };
    }

    // Move forwardLineZ backward until contact
    while (true) {
      const { farthestTop, farthestBottom } = findLineContact(forwardLineZ);
      if (farthestTop && farthestBottom) {
        forwardContactPoint = { top: farthestTop, bottom: farthestBottom };
        break;
      }
      forwardLineZ -= stepSize;
    }

    // Move backwardLineZ forward until contact
    while (true) {
      const { farthestTop, farthestBottom } = findLineContact(backwardLineZ);
      if (farthestTop && farthestBottom) {
        backwardContactPoint = { top: farthestTop, bottom: farthestBottom };
        break;
      }
      backwardLineZ += stepSize;
    }

    // Optionally place spheres at the contact points
    function createSphere(position, color) {
      const sphereGeometry = new THREE.SphereGeometry(1, 8, 8);
      const sphereMaterial = new THREE.MeshBasicMaterial({ color });
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.position.copy(position);
      scene.add(sphere);
    }

    createSphere(forwardContactPoint.top, 0xff0000);
    createSphere(forwardContactPoint.bottom, 0xff0000);
    createSphere(backwardContactPoint.top, 0x0000ff);
    createSphere(backwardContactPoint.bottom, 0x0000ff);

    // 5) Finally compute the length from forwardLineZ -> backwardLineZ
    const length = forwardLineZ - backwardLineZ;
    return length;
  }

  const length = measureModelLengthWithLines();

  // Return final measurements
  return {
    width,
    circumference,
    length
  };
}
/* ------------------- REMOVE SCANS ------------------- */
function removeScans() {
  if (footModel_left && scene_left) {
    scene_left.remove(footModel_left);
    footModel_left.geometry?.dispose();
    if (Array.isArray(footModel_left.material)) {
      footModel_left.material.forEach((m) => m.dispose());
    } else {
      footModel_left.material?.dispose();
    }
    footModel_left = null;
  }

  if (footModel_right && scene_right) {
    scene_right.remove(footModel_right);
    footModel_right.geometry?.dispose();
    if (Array.isArray(footModel_right.material)) {
      footModel_right.material.forEach((m) => m.dispose());
    } else {
      footModel_right.material?.dispose();
    }
    footModel_right = null;
  }

  files_loaded_left.value = false;
  files_loaded_right.value = false;
  width_left.value = null;
  width_right.value = null;
  circumference_left.value = null;
  circumference_right.value = null;
  length_left.value = null;
  length_right.value = null;

  removeSpheresAndMeasurements(scene_left);
  removeSpheresAndMeasurements(scene_right);
}

/* ------------------- MISC NAVIGATE ------------------- */
async function navigate() {
  store.scanLengthLeft = Math.round(length_left.value);
  store.scanWidthLeft = Math.round(width_left.value);
  store.scanCircumfenceLeft = Math.round(circumference_left.value);

  store.scanLengthRight = Math.round(length_right.value);
  store.scanWidthRight = Math.round(width_right.value);
  store.scanCircumfenceRight = Math.round(circumference_right.value);

  await router.push("/osb/foot-dimensions/" + store.category);
}

/* ------------------- MOVE/ROTATE MANUAL ------------------- */
function rotateModelOnZPlane(side, angleDeg) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef} =
      (side === "left")
          ? {
            footModel: footModel_left, scene: scene_left, widthRef: width_left,
            circumferenceRef: circumference_left, lengthRef: length_left
          }
          : {
            footModel: footModel_right, scene: scene_right, widthRef: width_right,
            circumferenceRef: circumference_right, lengthRef: length_right
          };

  if (!footModel) return;

  removeSpheresAndMeasurements(scene);
  let deltaRad = THREE.MathUtils.degToRad(angleDeg);
  footModel.rotation.z += deltaRad;

  if (rotationTimeout) clearTimeout(rotationTimeout);
  rotationTimeout = setTimeout(() => {
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  }, 1000);
}

function moveModelOnZPlane(side, xOff, zOff) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef} =
      (side === "left")
          ? {
            footModel: footModel_left, scene: scene_left,
            widthRef: width_left, circumferenceRef: circumference_left, lengthRef: length_left
          }
          : {
            footModel: footModel_right, scene: scene_right,
            widthRef: width_right, circumferenceRef: circumference_right, lengthRef: length_right
          };

  if (!footModel) return;

  removeSpheresAndMeasurements(scene);
  footModel.position.x += xOff;
  footModel.position.z += zOff;

  if (rotationTimeout) clearTimeout(rotationTimeout);
  rotationTimeout = setTimeout(() => {
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  }, 1000);
}

/* ------------------- DRAG HANDLERS ------------------- */
let isDraggingLeft = false;
let isDraggingRight = false;
const mouse = new THREE.Vector2();
const raycaster = new THREE.Raycaster();
let dragStartPositionLeft = new THREE.Vector3();
let dragStartPositionRight = new THREE.Vector3();

function startDraggingModel(side, clientX, clientY) {
  const {camera, footModel, controls} =
      (side === "left")
          ? {camera: camera_left, footModel: footModel_left, controls: controls_left}
          : {camera: camera_right, footModel: footModel_right, controls: controls_right};

  if (!footModel) return;

  const container = (side === "left") ? target_left.value : target_right.value;
  const rect = container.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObject(footModel);
  if (intersects.length > 0) {
    if (side === "left") {
      isDraggingLeft = true;
      controls.enabled = false;
      dragStartPositionLeft.copy(intersects[0].point);
    } else {
      isDraggingRight = true;
      controls.enabled = false;
      dragStartPositionRight.copy(intersects[0].point);
    }
  }
}

function dragModel(side, clientX, clientY) {
  const {camera, footModel, scene} =
      (side === "left")
          ? {camera: camera_left, footModel: footModel_left, scene: scene_left}
          : {camera: camera_right, footModel: footModel_right, scene: scene_right};

  if (!footModel) return;
  const dragging = (side === "left") ? isDraggingLeft : isDraggingRight;
  if (!dragging) return;

  removeSpheresAndMeasurements(scene);

  const container = (side === "left") ? target_left.value : target_right.value;
  const rect = container.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);

  // project on plane y=0
  const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
  const intersectP = new THREE.Vector3();
  raycaster.ray.intersectPlane(plane, intersectP);

  if (side === "left") {
    footModel.position.set(
        footModel.position.x + (intersectP.x - dragStartPositionLeft.x),
        footModel.position.y,
        footModel.position.z + (intersectP.z - dragStartPositionLeft.z)
    );
    dragStartPositionLeft.copy(intersectP);
  } else {
    footModel.position.set(
        footModel.position.x + (intersectP.x - dragStartPositionRight.x),
        footModel.position.y,
        footModel.position.z + (intersectP.z - dragStartPositionRight.z)
    );
    dragStartPositionRight.copy(intersectP);
  }
}

function stopDraggingModel(side) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef, controls} =
      (side === "left")
          ? {
            footModel: footModel_left, scene: scene_left,
            widthRef: width_left, circumferenceRef: circumference_left,
            lengthRef: length_left, controls: controls_left
          }
          : {
            footModel: footModel_right, scene: scene_right,
            widthRef: width_right, circumferenceRef: circumference_right,
            lengthRef: length_right, controls: controls_right
          };

  if (side === "left" && isDraggingLeft) {
    isDraggingLeft = false;
    controls.enabled = true;
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  } else if (side === "right" && isDraggingRight) {
    isDraggingRight = false;
    controls.enabled = true;
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  }
}

function onMouseDownLeft(e) { startDraggingModel("left", e.clientX, e.clientY); }

function onMouseMoveLeft(e) { dragModel("left", e.clientX, e.clientY); }

function onMouseUpLeft() { stopDraggingModel("left"); }

function onTouchStartLeft(e) {
  if (e.touches.length === 1) {
    startDraggingModel("left", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchMoveLeft(e) {
  if (e.touches.length === 1) {
    dragModel("left", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchEndLeft() { stopDraggingModel("left"); }

function onMouseDownRight(e) { startDraggingModel("right", e.clientX, e.clientY); }

function onMouseMoveRight(e) { dragModel("right", e.clientX, e.clientY); }

function onMouseUpRight() { stopDraggingModel("right"); }

function onTouchStartRight(e) {
  if (e.touches.length === 1) {
    startDraggingModel("right", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchMoveRight(e) {
  if (e.touches.length === 1) {
    dragModel("right", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchEndRight() { stopDraggingModel("right"); }
</script>

<style scoped>
canvas {
  border-radius: 15px !important;
}
</style>